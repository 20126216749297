import { Injectable } from '@angular/core';
import axios from 'axios'
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BitcoinRate  {
  private timestampMs: number = null
  public dollarToSatoshi:BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly dollarToSatoshiObservable: Observable<number> = this.dollarToSatoshi.asObservable()

  constructor() {
    this.timestampMs = new Date().getTime()
    axios({
      method: 'get',
      url: `https://api.coindesk.com/v1/bpi/currentprice.json`,
    })
    .then(response => {
      this.dollarToSatoshi.next(response.data.bpi.USD.rate_float)
    })
  
  }

  async refreshRates() {
    const now = new Date().getTime()
    const sixtyMins = 1000 * 60 * 60

    if (this.timestampMs === null ||  now > this.timestampMs + sixtyMins) {
      this.timestampMs = new Date().getTime()
      const response = await axios({
        method: 'get',
        url: `https://api.coindesk.com/v1/bpi/currentprice.json`,
      })
      this.dollarToSatoshi.next(response.data.bpi.USD.rate_float)
    }
  }
}
