export enum BitLabsQuestionType {
  Text = 'TEXT',
  Number = 'NUMBER',
  Single = 'SINGLE',
  Multi = 'MULTI',
  Date = 'DATE'
}

export interface BitLabsQuestionModel {
  network_id: number // ID of the network. Required in question specific requests. 0 in this case is a valid network_id can be passed to Bitlabs servers for getting/answering questions
  question_id: string
  question: {
    answers?: [{
      code: string
      localized_text: string
    }]
    id: string
    localized_text: string
    type: BitLabsQuestionType
  }
  country: string // E.g GB Country code in ISO 3166-1 alpha-2
  is_standard_profile: boolean // Flag is true if this question is asked because of our standard user profile that is asked for every user before we can filter surveys
  language: string // E.g. en Language code in ISO 639-1
}

export interface BitLabsSurveyModel {
  data: {
    qualification?: BitLabsQuestionModel
    surveys?: BitLabsSurveyInfoModel[]
    restriction_reason?: {
      using_vpn?: boolean
      banned_until?: string
      unsupported_country?: boolean
    }
  }
  status: string
  trace_id: string
}

export interface BitLabsSurveyInfoModel {
  country: string
  cpi: string
  details: {
    category: {
      icon_url: string
      name: string
    }
    network: {
      icon_url: string
      name: string
    } 
    difficulty: string
  }
  id: string
  language: string
  link: string
  loi: number
  network_id: number
  rating: number
  remaining: number
  tags: []
  value: string
}