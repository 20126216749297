import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { RetailerModel } from '../models/retailer.model';
import { ALL_RETAILERS_FS_PATH } from '../types';

@Injectable({
  providedIn: 'root'
})
export class RetailerService {

  constructor(
    private fs: AngularFirestore
  ) { }

  async getRetailer(id: string): Promise<RetailerModel> {
    const doc = await this.fs.doc(`${ALL_RETAILERS_FS_PATH}/${id}`).get().toPromise()
    return doc.data() as RetailerModel
  }
}
