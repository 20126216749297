import { Component, OnInit } from '@angular/core';
import { slideInOutAnimation } from 'src/app/animations/slide-in-out';
import { AuthService } from 'src/app/shared/services/auth.service';
import {
  SurveyService,
  SurveyState,
} from 'src/app/shared/services/survey.service';
import { BitLabsSurveyInfoModel } from '../models/bitlabs.model';
import { ScreensizeService } from 'src/app/shared/services/screensize.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss'],
  animations: [slideInOutAnimation],
})
export class SurveysComponent implements OnInit {
  public isDesktop = false;
  public surveys: Array<BitLabsSurveyInfoModel>;
  public title = 'Sats for surveys';
  public subtitle = 'Complete surveys to earn sats';
  public loading = true;
  public problemType = null;
  public onboardedBitlabs = null;

  constructor(
    private userService: UserService,
    private screensizeService: ScreensizeService,
    private authService: AuthService,
    private afsAnalytics: AngularFireAnalytics,
    private surveyService: SurveyService,
    private router: Router,
    private platform: Platform
  ) {}

  async ngOnInit(): Promise<void> {
    this.checkForProblems();

    this.screensizeService.isDesktopView().subscribe((isDesktop) => {
      this.isDesktop = ScreensizeService.switchToDesktop(
        this.isDesktop,
        isDesktop
      );
    });

    await this.refreshSurveys();

    this.onboardedBitlabs = this.userService.onboardedBitlabs.value;
    this.userService.onboardedBitlabsObservable.subscribe((onboarded) => {
      this.onboardedBitlabs = onboarded;
      this.loading = false;
    });

    this.surveyService.surveyListObservable.subscribe((surveys) => {
      this.surveys = surveys;
    });

    const func = async (visible: boolean) => {
      try {
        this.loading = true;
        await this.surveyService.refreshSurveys(this.authService.userId);
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    };

    document.addEventListener('visibilitychange', async function () {
      func(!document.hidden);
    });
  }

  async refreshSurveys() {
    try {
      await this.surveyService.refreshSurveys(this.authService.userId);
    } catch (error) {
      console.error(error);
    }
  }

  showQualificationButton() {
    return (
      this.surveyService.hasQualificationQuestion() &&
      (!this.surveys || this.surveys?.length < 9)
    );
  }

  starterSurvey() {
    this.afsAnalytics.logEvent('starter_survey_click', {
      network: 'bitlabs'
    })

    if (this.platform.is('capacitor')) {
      this.router.navigate(['/survey-redirect-mob'], {
        queryParams: {
          // surveyLink: `${environment.websiteBaseUrl}/starter-survey?userId=${this.authService.userId}`,
          surveyLink: `${environment.websiteBaseUrl}/starter-survey`,
          surveyTitle: 'Satsy Survey',
        },
      });
    } else {
      window.open(`${window.origin}/starter-survey`, '_blank');
      //window.open(`${window.origin}/starter-survey?userId=${this.authService.userId}`, '_blank');
    }
  }

  qualification() {
    this.afsAnalytics.logEvent('qualification_survey_click', {
      network: 'bitlabs'
    })

    //window.open(`${window.origin}/qualification-survey?userId=${this.authService.userId}`, '_blank');
    window.open(`${window.origin}/qualification-survey`, '_blank');
  }

  async checkForProblems() {
    try {
      const surveyState = await this.surveyService.surveyState(
        this.authService.userId
      );

      if (surveyState === SurveyState.Ok) {
        this.problemType = null;
      } else if (surveyState === SurveyState.BannedUntil) {
        this.problemType = 'banned';
      } else if (surveyState === SurveyState.VPN) {
        this.problemType = 'vpn';
      } else if (surveyState === SurveyState.UnsupportedCountry) {
        this.problemType = 'unsupportedcountry';
      } else if (surveyState === SurveyState.Error) {
        this.problemType = 'error';
      }
    } catch (error) {
      console.error(error);
      return SurveyState.Error;
    }
  }

  showStarterSurveyCard() {
    return (
      !this.onboardedBitlabs && this.surveyService.hasQualificationQuestion()
    );
  }
}
