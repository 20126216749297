import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ScreensizeService } from 'src/app/shared/services/screensize.service';
import { SurveyService } from 'src/app/shared/services/survey.service';
import { LoadingController } from '@ionic/angular';
import { DialogProfile } from './dialog-profile/dialog-profile';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public isDesktop = false;
  user = {
    Name: '',
    Email: '',
    Country: '',
    Gender: '',
    DOB: '',
  };
  constructor(
    private authService: AuthService,
    private fs: AngularFirestore,
    private afsAnalytics: AngularFireAnalytics,
    private surveyService: SurveyService,
    public dialog: MatDialog,
    private screensizeService: ScreensizeService,
    public loadingController: LoadingController
  ) {
    this.screensizeService.isDesktopView().subscribe((isDesktop) => {
      this.isDesktop = ScreensizeService.switchToDesktop(
        this.isDesktop,
        isDesktop
      );
    });
  }
  async ngOnInit() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
      duration: 2000,
    });
    await loading.present();
    await this.getUserProfile();
    loading.dismiss();
  }

  async getUserProfile() {
    const userData = await this.authService.getUserProfile();
    this.user.Name = userData.displayName ? userData.displayName : '';
    this.user.Email = userData.email;
    this.user.DOB = userData.dob ? userData.dob : '';
    this.user.Gender = userData.gender ? userData.gender : '';
    this.user.Country = userData.defaultCountry ? userData.defaultCountry : '';
  }

  openDialog(key: string, value: any): void {

    if (key.includes('Password')) {
      this.afsAnalytics.logEvent('update_password_clicked', {
        placement: 'profile_page'
      })
    }

    this.dialog
      .open(DialogProfile, {
        width: this.isDesktop == true ? '480px' : '300px',
        data: { key: key, value: value },
      })
      .afterClosed()
      .subscribe((result) => {
        setTimeout(() => {
          this.getUserProfile();
        }, 300);
      });
  }
  async deleteUser() {
    this.surveyService.deleteUser(this.authService.userId).catch((error) => {
      console.log(error);
    });
  }
}
