import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyService } from '../../../shared/services/survey.service';

@Component({
  selector: 'app-survey-problem',
  templateUrl: './survey-problem.component.html',
  styleUrls: ['./survey-problem.component.scss']
})
export class SurveyProblemComponent {

  @Input() public type:string = 'error'

  constructor(
    private surveyService: SurveyService,
    public router: Router,
    private route: ActivatedRoute) { }


  title(): string {
    switch (this.type) {
      case 'vpn':
        return 'VPN.'
      case 'unsupportedcountry':
        return 'Unsupported Country.'
      case 'banned':
        return 'Blocked.'
      default:
        return 'Error.'
    }
  }

  header(): string {
    switch (this.type) {
      case 'vpn':
        return 'VPNs are not allowed'
      case 'unsupportedcountry':
        return 'No surveys available'
      case 'banned':
        return 'Access suspended'
      default:
        return 'Sorry about that'
    }
  }

  message(): string {
    switch (this.type) {
      case 'vpn':
        return 'Unfortunately, you cannot access surveys through a VPN. The survey companies need to check your country of origin to ensure the participants come from their target audience. Please disconnect the VPN and try again.'
      case 'unsupportedcountry':
        return 'Ah sorry, we don\'t have an surveys for your country right now.  Please check back again in the future.'
      case 'banned':
        return `Your answers in a previous survey caused your account to be temporarily locked.  This is a security measure to ensure surveys are completed by honest participants. Your account will be available again on ${this.surveyService.getBannedDate()}`
      default:
        return 'There was an error getting your surveys, we are looking into this error!'
    }
  }

  buttonLabel(): string {
    switch (this.type) {
      case 'vpn':
        return 'Try again'
      case 'unsupportedcountry':
        return 'Ok'
      case 'banned':
        return 'Ok'
      default:
        return 'Ok'
    }
  }

  click(): void {
    switch (this.type) {
      case 'vpn':
        this.router.navigateByUrl('surveys')
        break
      case 'unsupportedcountry':
        this.router.navigateByUrl('home')
        break
      case 'banned':
        this.router.navigateByUrl('home')
        break
      default:
        this.router.navigateByUrl('home')
        break
    }
  }
}
