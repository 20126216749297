import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-survey-success',
  templateUrl: './survey-success.component.html',
  styleUrls: ['./survey-success.component.scss']
})
export class SurveySuccessComponent implements OnInit {

  constructor(private afsAnalytics: AngularFireAnalytics) { }

  ngOnInit(): void {
    this.afsAnalytics.logEvent('finished_survey', {
      result: 'survey_success_page'
    })
  }

  close(): void {
    window.close()
  }
}
