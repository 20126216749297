import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import {
  AngularFireRemoteConfigModule,
  SETTINGS,
} from '@angular/fire/remote-config';
import { AuthService } from './shared/services/auth.service';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperIntl, MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { QRCodeModule } from 'angularx-qrcode';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SurveysComponent } from './components/surveys/surveys/surveys.component';
import { ShoppingComponent } from './components/shopping/shopping/shopping.component';
import { GamesComponent } from './components/games/games/games.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { WalletComponent } from './components/account/wallet/wallet.component';
import { QualificationComponent } from './components/surveys/qualification/qualification.component';
import { MaterialElevationDirective } from './animations/material-elevation.directive';
import { SafePipe } from './shared/pipes/safe-pipe';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInCardComponent } from './components/sign-in/sign-in-card/sign-in-card.component';
import { ForgotPasswordCardComponent } from './components/sign-in/forgot-password-card/forgot-password-card.component';
import { JoinCardComponent } from './components/sign-in/join-card/join-card.component';
import { VerifyCardComponent } from './components/sign-in/verify-card/verify-card.component';
import { StartSurveyComponent } from './components/surveys/start-survey/start-survey.component';
import { WithdrawComponent } from './components/account/withdraw/withdraw.component';
import { SharedModule } from './shared/components/shared.module';
import { HistoryTableComponent } from './components/account/history-table/history-table.component';
import { DatePipe } from '@angular/common';
import { SurveyErrorComponent } from './components/surveys/survey-error/survey-error.component';
import { SurveySuccessComponent } from './components/surveys/survey-success/survey-success.component';
import { SurveyScreenoutComponent } from './components/surveys/survey-screenout/survey-screenout.component';
import { SurveyProblemComponent } from './components/surveys/survey-problem/survey-problem.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { IonicModule } from '@ionic/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { RetailerComponent } from './components/retailers/retailer/retailer.component';
import { FilterPipe } from './shared/pipes/filter.pipe';
import { ApiService } from './shared/services/api.service';
import { BalanceCardComponent } from './components/account/balance-card/balance-card.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { SurveyCardComponent } from './components/surveys/survey-card/survey-card.component';
import { DialogRetailer } from './components/retailers/dialog-retailer/dialog-retailer';
import { SupportComponent } from './components/support/support.component';
import { MissingSatsbackComponent } from './components/missing-satsback/missing-satsback.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FaqComponent } from './components/faq/faq.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { RedirectComponent } from './components/redirect/redirect.component';
import { DialogRetailerSuccess } from './components/retailers/dialog-retailer-success/dialog-retailer-success';
import { DialogProfile } from './components/account/profile/dialog-profile/dialog-profile';
import { HistoryCellComponent } from './components/account/history-table/history-cell/history-cell.component';
import { RetailerCardComponent } from './components/retailers/retailer-card/retailer-card.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { ShoppingSearchService } from './shared/services/shopping-search.service';
import { WalletService } from './shared/services/wallet.service';
import { SurveyRedirectComponent } from './components/surveys/survey-redirect/survey-redirect.component';
import { DialogSurveyComponent } from './components/surveys/survey-redirect/dialog/dialog-survey.component';
import { QualificationCardComponent } from './components/surveys/qualification-card/qualification-card.component';
import { StarterSurveyComponent } from './components/surveys/starter-survey/starter-survey.component';
import { SurveyMessageComponent } from './components/surveys/survey-message/survey-message.component';
import { QualificationSurveyComponent } from './components/surveys/qualification-survey/qualification-survey.component';
import { pageTransition } from './page-transition';
import { RetailerService } from './shared/services/retailer.service';
import { SurveyRedirectMobileComponent } from './components/surveys/survey-redirect-mob/survey-redirect-mob';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SurveysComponent,
    ShoppingComponent,
    GamesComponent,
    ProfileComponent,
    WalletComponent,
    QualificationComponent,
    MaterialElevationDirective,
    SafePipe,
    DashboardComponent,
    SignInCardComponent,
    ForgotPasswordCardComponent,
    JoinCardComponent,
    VerifyCardComponent,
    StartSurveyComponent,
    WithdrawComponent,
    HistoryTableComponent,
    HistoryCellComponent,
    SurveyRedirectComponent,
    SurveyErrorComponent,
    SurveySuccessComponent,
    SurveyScreenoutComponent,
    SurveyProblemComponent,
    QualificationCardComponent,
    DialogSurveyComponent,
    FooterComponent,
    HomeComponent,
    RetailerComponent,
    RetailerCardComponent,
    FilterPipe,
    BalanceCardComponent,
    SurveyCardComponent,
    DialogRetailer,
    DialogRetailerSuccess,
    SupportComponent,
    MissingSatsbackComponent,
    ContactUsComponent,
    FaqComponent,
    RedirectComponent,
    DialogProfile,
    MobileMenuComponent,
    StarterSurveyComponent,
    SurveyMessageComponent,
    QualificationSurveyComponent,
    SurveyRedirectMobileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireRemoteConfigModule,
    AngularFireFunctionsModule,
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatGridListModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatSnackBarModule,
    MatStepperModule,
    MatExpansionModule,
    QRCodeModule,
    ClipboardModule,
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ mode: 'md', navAnimation: pageTransition }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    RetailerService,
    AuthService,
    ShoppingSearchService,
    WalletService,
    ApiService,
    StatusBar,
    InAppBrowser,
    {
      provide: SETTINGS,
      useValue: { minimumFetchIntervalMillis: 10_000 },
    },
    DatePipe,
    FilterPipe,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
