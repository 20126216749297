import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @Input() showHelp = true
  constructor(
    private afsAnalytics: AngularFireAnalytics,
    private router: Router
  ) {}

  ngOnInit() {

  }

  goToLink(link: string, event: string) {
    this.afsAnalytics.logEvent(event, {
      placement: 'footer'
    })
    window.open(link, '_blank')
  }

  
  goToRoute(route: string, event: string) {
    this.afsAnalytics.logEvent(event, {
      placement: 'footer'
    })
    this.router.navigateByUrl(route)
  }
}
