import { Injectable } from '@angular/core';
import { CurrencyCode } from '../types'
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database'
import { BitcoinRate } from './bitcoin-rate.service';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FxService {

  public isReady:BehaviorSubject<boolean> = new BehaviorSubject(false)
  public readonly isReadyObservable: Observable<boolean> = this.isReady.asObservable()

  public bitcoinRates:BehaviorSubject<any> = new BehaviorSubject(null)
  public readonly bitcoinRatesObservable: Observable<any> = this.bitcoinRates.asObservable()

  private _bitcoinRates
  
  constructor(private _db: AngularFireDatabase) { 
    this._db.object('fx/bitcoin').valueChanges().subscribe(rates => {
      this._bitcoinRates = rates
      this.isReady.next(true)
      this.bitcoinRates.next(this._bitcoinRates)
    })
  }

  convertToBitcoin(currency: CurrencyCode, amount: number): number {
    if (this._bitcoinRates) {
      return this._bitcoinRates[currency] / amount
    }
    return 0
  }
}
