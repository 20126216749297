import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { RetailerModel } from 'src/app/shared/models/retailer.model';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { RetailerService } from 'src/app/shared/services/retailer.service';
import { currencyCodeToSymbol } from 'src/app/shared/types';

import { Plugins } from '@capacitor/core';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
const { Browser } = Plugins;

@Component({
  selector: 'dialog-retailer',
  templateUrl: 'dialog-retailer.html',
  styleUrls: ['./dialog-retailer.scss'],
})
export class DialogRetailer implements OnInit {
  progress: number = 0;
  total: number = 10;
  adBlocker: boolean = false;
  retailer: RetailerModel;
  showProgressBar: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<DialogRetailer>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ngZone: NgZone,
    private apiService: ApiService,
    private config: AngularFireRemoteConfig,
    private retailerService: RetailerService,
    private afsAnalytics: AngularFireAnalytics,
    private authService: AuthService
  ) {
    this.dialogRef.afterClosed().subscribe(() => {
      this.openWindow();
    });
  }

  ngOnInit() {
    this.retailerService
      .getRetailer(this.data.retailerId)
      .then((retailer) => (this.retailer = retailer));
  }

  ngAfterViewInit() {
    this.checkAdBlocker();
  }

  async checkAdBlocker() {
    this.adBlocker = await this.apiService.checkAdBlocker();
    if (!this.adBlocker) {
      this.showProgressBar = true;
      const timeInterval = setInterval(() => {
        if (this.progress < 5) {
          this.progress += 0.1;
        } else {
          clearInterval(timeInterval);
          this.showProgressBar = false;
          this.dialogRef.close();
        }
      }, 100);
    }
    else {
      this.afsAnalytics.logEvent('retailer_redirect_adblocker', {
        userId: this.authService.userId,
        retailerId: this.retailer.id
      })
    }
  }


  async openWindow() {
    if (this.data.retailerLink == null) {
      const callable = firebase
        .app()
        .functions('us-central1')
        .httpsCallable('retailerLink');
      callable({
        retailerId: this.retailer.id,
      })
        .then(async (data) => {

          this.afsAnalytics.logEvent('retailer_redirect', {
            userId: this.authService.userId,
            retailerId: this.retailer.id,
            clickId: data.data.clickId
          })

          await Browser.open({
            url: data.data.link,
            windowName: '_parent',
          });
        })
        .catch((error) => console.error(error));
    } else if (this.data.retailerLink != null) {

      // TODO - NEEDS ANALYTICS EVENT
      
      await Browser.open({
        url: this.data.retailerLink,
        windowName: '_parent',
      });
    }
  }

  async currencyCodeToSymbol(code: string) {
    // await this.config.fetchAndActivate()
    // const currencies = await this.config.getValue('currencies')
    // console.log(currencies)

    return currencyCodeToSymbol(code);
  }
}
