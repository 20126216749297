// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  websiteBaseUrl: 'https://sandbox.app.satsy.com',
  satsyApiBaseUrl: 'https://sandbox.api.satsy.com',
  satsyRewardsBaseUrl: 'https://sandbox.lightning.satsy.com',
  bitlabsApiKey: 'f3d88881-9e59-4337-807f-24630a353062',
  firebase: {
    apiKey: 'AIzaSyCcjo0DLXTTkysvb1fFHnWHLTQIYIUPQ68',
    authDomain: 'satsy-sandbox.firebaseapp.com',
    databaseURL: 'https://satsy-sandbox.firebaseio.com',
    projectId: 'satsy-sandbox',
    storageBucket: 'satsy-sandbox.appspot.com',
    messagingSenderId: '35039295450',
    appId: '1:35039295450:web:bfb0adfca6a41db08b72e3',
    measurementId: 'G-8K4M3SXDV5',
  },
  algolia: {
    appID: 'U9VKZRC2SC',
    searchKey: 'dffa8f36ac1b9bebc6365b7ced3825aa',
  },
  gtmId: 'GTM-M365T6Z',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
