import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {

  
  public onboardedBitlabs:BehaviorSubject<boolean | null> = new BehaviorSubject(null)
  public readonly onboardedBitlabsObservable: Observable<boolean> = this.onboardedBitlabs.asObservable()
  
  private userSubscription: Subscription

  constructor(
    public afAuth: AngularFireAuth,
    public fs: AngularFirestore) {
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        
        this.userSubscription = this.fs.doc(`users/${user.uid}`)
        .valueChanges().subscribe((snapshot: any) => {
          this.onboardedBitlabs.next(snapshot.onboardedBitlabs)
        })
      }
      else {
        this.userSubscription?.unsubscribe()
      }
    });
  }

  ngOnDestroy() {
    this.userSubscription?.unsubscribe()
  }
}
