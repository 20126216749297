import { Injectable } from '@angular/core';
import { BitLabsQuestionType } from '../models/bitlabs.model';
import { SurveyService } from '../../../shared/services/survey.service';
import { QualificationComponentConfig, QualificationInputType } from './qualification.config';

@Injectable({
  providedIn: 'root'
})
export class QualificationService {

  constructor(
    private surveyService: SurveyService) {
  }

  currentQuestion() : QualificationComponentConfig {
    const qualification = this.surveyService.qualificationQuestion()
    const skippable = !qualification?.is_standard_profile
  
    if (qualification?.question.type === BitLabsQuestionType.Text) {
      return {
        header: qualification.question.localized_text,
        inputType: QualificationInputType.Text,
        skippable: skippable
      }
    }
    else if (qualification?.question.type === BitLabsQuestionType.Number) {
      return {
        header: qualification.question.localized_text,
        inputType: QualificationInputType.Number,
        skippable: skippable
      }
    }
    else if (qualification?.question.type === BitLabsQuestionType.Single) {
      return {
        header: qualification.question.localized_text,
        inputType: QualificationInputType.Single,
        possibleAnswers: qualification.question.answers,
        skippable: skippable
      }
    }
    else if (qualification?.question.type === BitLabsQuestionType.Multi) {
      return {
        header: qualification.question.localized_text,
        inputType: QualificationInputType.Multi,
        possibleAnswers: qualification.question.answers,
        skippable: skippable
      }
    }
    else if (qualification?.question.type === BitLabsQuestionType.Date) {
      return {
        header: qualification?.question.localized_text,
        inputType: QualificationInputType.Date,
        possibleAnswers: qualification.question.answers,
        skippable: skippable
      }
    }
    return null
  }

  isFinished() {
    return !this.surveyService.hasQualificationQuestion() 
  }
}
