import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {

  constructor(
    private afsAnalytics: AngularFireAnalytics,
    private router: Router) { }

  ngOnInit() {}
  goToSocialAccount(link) {
    window.open(link, '_blank');
  }

  openSupport() {
    this.afsAnalytics.logEvent('faq_button_clicked', {
      placement: 'support_page'
    })
    window.open('https://help.satsy.com', '_blank');
  }

  openMissingSatsback() {
    this.afsAnalytics.logEvent('missing_satsback_button_clicked', {
      placement: 'support_page'
    })
    this.router.navigateByUrl('missing-satsback')
  }

  openContactUs() {
    this.afsAnalytics.logEvent('contact_us_button_clicked',  {
      placement: 'support_page'
    })
    this.router.navigateByUrl('contact-us')
  }
}
