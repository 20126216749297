import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ScreensizeService } from 'src/app/shared/services/screensize.service';
import { IonInput, Platform, ToastController } from '@ionic/angular';
import { DialogRetailerSuccess } from '../../retailers/dialog-retailer-success/dialog-retailer-success';
import { MatDialog } from '@angular/material/dialog';
import { ShoppingSearchService } from 'src/app/shared/services/shopping-search.service';
import { Subscription } from 'rxjs';
import { RetailerCard } from 'src/app/shared/types';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-shopping',
  templateUrl: './shopping.component.html',
  styleUrls: ['./shopping.component.scss'],
})
export class ShoppingComponent implements OnInit, OnDestroy {
  @ViewChild('search') searchElement: IonInput;
  public isDesktop = false;
  public filter: boolean = false;
  public filter_close: boolean = true;
  public shops: Array<RetailerCard>;
  public loading: HTMLIonLoadingElement;
  public showSpinner: boolean = false;
  public searchText = ''
  public selectedCountry: string = '';
  public selectedCategory: string = '';
  public selectedSort: string = 'retailers_popularity';
  public countries: any[] = [];
  public categories: any[] = [];
  public isReady = true

  private searchSubscription: Subscription
  private searchingSubscription: Subscription
  private searchReadySubscription: Subscription

  constructor(
    private searchService: ShoppingSearchService,
    public authService: AuthService,
    private afsAnalytics: AngularFireAnalytics,
    private screensizeService: ScreensizeService,
    public toastController: ToastController,
    private platform: Platform,
    public dialog: MatDialog,
    private cRef: ChangeDetectorRef
  ) {
    this.screensizeService.isDesktopView().subscribe((isDesktop) => {
      this.isDesktop = ScreensizeService.switchToDesktop(
        this.isDesktop,
        isDesktop
      );
    });
  }

  ngOnInit() {

    this.isReady = this.searchService.isReady.value
    this.searchReadySubscription = this.searchService.isReadyObservable.subscribe(isReady => {
      this.isReady = isReady
      if (isReady) {
        this.selectedSort = this.searchService.getSort()
        this.searchText = this.searchService.getSearchText()
        this.selectedCategory = this.searchService.getCategory()
        this.selectedCountry = this.searchService.getCountry()
        this.countries = this.searchService.getCountries()
        this.categories = this.searchService.getCategories()
        this.cRef.detectChanges()
      }
    })

    this.showSpinner = false
    this.searchingSubscription = this.searchService.isSearchingObservable.subscribe(searching => {
      this.showSpinner = searching
    })
    
    this.shops = this.searchService.shops.value
    this.searchSubscription = this.searchService.shopsObservable.subscribe(shops => {
      this.shops = shops
    })
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe()
    this.searchingSubscription?.unsubscribe()
    this.searchReadySubscription?.unsubscribe()
  }

  openFilter() {
    this.filter = true;
    this.filter_close = false;
  }

  closeFilter() {
    this.filter = false;
    this.filter_close = true;
  }

  setSearchText(e) {
    this.searchService.setSearchText(e.target.value)
    this.searchService.search()
    .catch(error => console.error(error))
  }

  handleEnterClick() {
    this.closeFilter()
    this.afsAnalytics.logEvent('search_retailer', {
      search: this.searchText,
      placement: 'shopping'
    })

    this.searchService.search()
    .catch(error => console.error(error))
  }

  clearInput() {
    if (this.searchService.getSearchText() != '') {
      this.searchText = ''
      this.searchService.setSearchText('')
      this.searchService.search()
      .catch(error => console.error(error))
    }
  }

  openDialogSuccess(mainData) {
    this.dialog.open(DialogRetailerSuccess, {
      width:
        this.platform.is('desktop') || this.platform.is('pwa')
          ? '480px'
          : '328px',
      data: mainData,
    });
  }

  changeSorting(e) {
    this.searchService.setSort(e.detail.value)
    this.searchService.search()
    .catch(error => console.error(error))
    this.closeFilter()
  }

  changeCategory(e) {
    this.searchService.setCategory(e.detail.value)
    this.searchService.search()
    .catch(error => console.error(error))
    this.closeFilter()
  }

  changeCountry(e) {
    this.searchService.setCountry(e.detail.value)
    this.searchService.search()
    .catch(error => console.error(error))
    this.closeFilter()
  }

  loadMoreShops() {
    this.searchService.loadMoreShops()
  }

  canLoadMoreShops() {
    return this.searchService.canLoadMoreShops()
  }
}
