import { SurveyState } from "../../../shared/services/survey.service";

export enum QualificationInputType {
  Text = 'TEXT',
  Number = 'NUMBER',
  Single = 'SINGLE',
  Multi = 'MULTI',
  Date = 'DATE'
}

export enum QualificationQuestionType {
  Zip = 'ZIPCODE',
  BirthDate = 'BIRTHDATE',
  Gender = 'GENDER',
  Region = 'REGION',
  EmploymentIndustry = 'Employment Industry',
  StandardEmployment = 'STANDARD_EMPLOYMENT',
  StandardHHI = 'STANDARD_HHI',
  StandardIndustryPersonal = 'STANDARD_INDUSTRY_PERSONAL',
  StandardJobTitle = 'STANDARD_JOB_TITLE',
  StandardNumberOfEmployees = 'STANDARD_NO_OF_EMPLOYEES',
  StandardEducation = 'STANDARD_EDUCATION',
  StandardCompanyRevenue = 'STANDARD_COMPANY_REVENUE',
  StandardCompanyDepartment = 'STANDARD_COMPANY_DEPARTMENT',
  StandardRelationship = 'STANDARD_RELATIONSHIP',
  StandardHouseholdType = 'STANDARD_HOUSEHOLD_TYPE',
  StandardKids = 'KIDS_STANDARD',
  StandardHomeOwner = 'STANDARD_HOME_OWNER',
}

export interface QualificationComponentConfig {
  title?: string
  header?: string
  message?: string
  inputType?: QualificationInputType
  inputOptions?: Array<string>
  possibleAnswers?: {
    code: string,
    localized_text: string
  }[]
  skippable?: boolean
}
