import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SurveyService, SurveyState } from 'src/app/shared/services/survey.service';
import { SurveyMessage } from '../survey-message/survey-message.component';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { FxService } from 'src/app/shared/services/fx.service';
import { CurrencyCode } from 'src/app/shared/types';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { ActivatedRoute } from '@angular/router';

enum StarterSurveyState {
  Start,
  HowItWorks,
  Privacy,
  Begin,
  Questions,
  Finish,
  Problem
}

@Component({
  selector: 'app-starter-survey',
  templateUrl: './starter-survey.component.html',
  styleUrls: ['./starter-survey.component.scss']
})
export class StarterSurveyComponent implements OnInit {

  StarterSurveyState: typeof StarterSurveyState = StarterSurveyState
  public loading = true
  public state: StarterSurveyState = StarterSurveyState.Start
  public problemType = null
  public message: SurveyMessage = null
  public progress = 0.02
  private userId = null
  private starterReward: number
  private rates = null

  private startMessage: SurveyMessage = {
    header: 'Earn sats for surveys!',
    message: 'This first survey is a questionnaire to gather basic profile information. We use this to match you with surveys. You may be asked the same question in different ways, which is normal.',
    button: 'Begin',
    action: () => { this.setState(StarterSurveyState.HowItWorks) }
  }

  private howItWorksMessage: SurveyMessage = {
    header: 'How it works',
    message: 'Every time you complete a survey like this, you will receive your reward in your Satsy wallet. This first survey should take around 5 minutes. Once complete, you will have a list of more surveys to choose from.',
    button: 'Next',
    action: () => { this.setState(StarterSurveyState.Privacy) }
  }

  private privacyMessage: SurveyMessage = {
    header: 'Privacy',
    message: 'We partner with Bitlabs to aggregate surveys for you.  They are based in the EU so follow strict privacy rules. They also make it easy to access and delete data stored.  All your answers in this survey are stored by Bitlabs, not Satsy. So your data is only saved in one place.',
    button: 'Next',
    action: () => { this.setState(StarterSurveyState.Begin) }
  }

  private beginMessage: SurveyMessage = {
    header: 'Let\'s begin',
    message: 'Your answers are pseudoanonymous, i.e. not directly tied to your identity. But each survey targets a specific audience. So it is important to answer truthfully, otherwise you can be blocked from future surveys.  Let\s begin your first survey and stack some sats!',
    button: 'Start survey',
    action: () => {
      if (this.surveyService.hasQualificationQuestion()) {
        this.answered()
        this.state = StarterSurveyState.Questions 
      } 
      else {
        this.setState(StarterSurveyState.Finish)
      }
    } 
  }

  private finishMessage: SurveyMessage = {
    header: 'Finish',
    message: 'That\'s your first survey completed! You reward has been added to your Satsy wallet, and you now have access to more surveys!',
    button: 'Close',
    action: () => { window.close() }
  }

  constructor(
    private authService: AuthService,
    private surveyService: SurveyService,
    private fxService: FxService,
    private config: AngularFireRemoteConfig,
    private route: ActivatedRoute) {

    this.message = this.startMessage
  }

  async ngOnInit(): Promise<void> {

    this.route.queryParams.subscribe(async params => {
      try {    
        this.userId = params['userId']
        if (!this.userId) {
          this.userId = this.authService.userId
        }
        await this.checkForProblems()
        await this.surveyService.refreshSurveys(this.userId)
        await this.config.fetchAndActivate()
        this.starterReward = await this.config.getNumber('starter_survey_reward')
        this.fxService.isReadyObservable.subscribe(ready => {
          if (ready) {
            this.fxService.bitcoinRatesObservable.subscribe(rates => {
              this.rates = rates
            })
          }
        })
      }
      catch (error) {
        console.error(error)
      }  
      this.loading = false   
    })
  }

  setState(state: StarterSurveyState): void {
    this.state = state

    switch (state) {
      case StarterSurveyState.Start:
        this.answered()
        this.message = this.startMessage
        break;
        
      case StarterSurveyState.HowItWorks:
        this.answered()
        this.message = this.howItWorksMessage
        break;

      case StarterSurveyState.Privacy:
        this.answered()
        this.message = this.privacyMessage
        break;

      case StarterSurveyState.Begin:
        this.answered()
        this.message = this.beginMessage
        break;
          
      case StarterSurveyState.Finish:
        this.progress = 1.0
        this.message = this.finishMessage
        break;
        
      default:
        break;
    }
  }

  async checkForProblems() {
    try {
      const surveyState =  await this.surveyService.surveyState(this.userId)

      if (surveyState === SurveyState.Ok) {
         this.problemType = null
      }
      else if (surveyState === SurveyState.BannedUntil) {
        this.problemType = 'banned'
        this.state = StarterSurveyState.Problem
      }          
      else if (surveyState === SurveyState.VPN) {
        this.problemType = 'vpn'
        this.state = StarterSurveyState.Problem
      }         
      else if (surveyState === SurveyState.UnsupportedCountry) {
        this.problemType = 'unsupportedcountry'
        this.state = StarterSurveyState.Problem
      }          
      else if (surveyState === SurveyState.Error) {
        this.problemType = 'error'
        this.state = StarterSurveyState.Problem
      }

     
   }
   catch (error) {
      console.error(error)
      return SurveyState.Error
   }
  }

  get satsPayable(): string {
    if (this.rates) {
      return `${Math.round(this.usdPayable / this.rates[CurrencyCode.USD] * 100000000)}`
    }
    return '0'
  }

  get usdPayable(): number {
    return this.starterReward
  }
  
  answered(): void {

    if (this.progress < 0.98) {
      this.progress += 0.02
    }
  }

  finished(): void {
    this.loading = true
    this.authService.afAuth.currentUser.then(user => {
      user.getIdToken().then(token => {
        axios({
          method: 'post',
          url: `${environment.satsyApiBaseUrl}/v0/survey/starter/complete`,
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: {
            network: 'bitlabs'
          }
        })
        .then(() => {
          this.setState(StarterSurveyState.Finish)
          this.loading = false
        })
        .catch(error => {
          console.error(error)
        })
      })
    })
  }
}
