import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SurveyService } from 'src/app/shared/services/survey.service';
import { BitLabsSurveyInfoModel } from '../../models/bitlabs.model';

@Component({
  selector: 'app-dialog-survey',
  templateUrl: './dialog-survey.component.html',
  styleUrls: ['./dialog-survey.component.scss'],
})
export class DialogSurveyComponent implements OnInit {
  
  private survey: BitLabsSurveyInfoModel

  constructor(    
    private surveyService: SurveyService,
    public dialogRef: MatDialogRef<DialogSurveyComponent>) { 
    this.survey = JSON.parse(window.localStorage.getItem('surveyData'));
  }

  ngOnInit() {
    this.redirectToSurvey()
  }

  redirectToSurvey() {
    const user = JSON.parse(localStorage.getItem('user'));

    this.surveyService.surveyClicked(this.survey, user.stsTokenManager.accessToken)
    .then(response => {
      const clickId = response.data.data.clickId
      this.surveyService.surveyStarted(this.survey.id, clickId, user.stsTokenManager.accessToken)
      .then(response => {
        const rewardId = response.data.data.rewardId
        const link = `${this.survey.link}&tags=rewardId%3D${rewardId}`
        window.open(link, "_parent") 
      })
      .catch(error => console.error(error))
    })
    .catch(error => console.error(error))
  }

}
