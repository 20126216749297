import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { environment } from 'src/environments/environment';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import { RetailerCard } from '../types';

const client = algoliasearch(
  environment.algolia.appID,
  environment.algolia.searchKey
);

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private remoteConfig: AngularFireRemoteConfig,
    private angularFirestore: AngularFirestore
  ) {}

  async getRetailersCategories() {
    await this.remoteConfig.fetchAndActivate();
    const res = await this.remoteConfig.getString('retailer_categories');
    let obj = JSON.parse(res);
    let categories = [];
    for (var i in obj) {
      categories.push(obj[i]);
    }
    return categories[0];
  }
  async getSupportedCountries() {
    await this.remoteConfig.fetchAndActivate();
    const res = await this.remoteConfig.getString('supported_countries');
    let obj = JSON.parse(res);
    let countries = [];

    for (var i in obj) {
      obj[i].unshift({
        countryCode: 'ALL',
        countryName: 'All Countries',
      });

      countries.push(obj[i]);
    }

    return countries[0];
  }

  async searchRetailers(
    textToSearch: string,
    selectedCountry: string,
    selectedCategory: string,
    selectedSort: string,
    limit: number
  ): Promise<Array<RetailerCard>> {

    const category =
      selectedCategory != 'All' ? parseInt(selectedCategory) : null;
    const country = (selectedCountry && selectedCountry !== 'ALL') ? selectedCountry : null;


    const attributesToRetrieve = [
      'retailerId',
		  'logo',
      'logo_100x100',
      'image',
      'image_600x200',
		  'displayName',
      'status',
		  'highestCommissionAmount', 
		  'highestCommissionType']

    const indexOn = selectedSort.includes('retailers_highest_commission')
      ? 'retailers_highest_commission'
      : selectedSort;
    const commissionType = selectedSort.includes('$')
      ? '$'
      : selectedSort.includes('%')
      ? '%'
      : null;

    const documentRef = client.initIndex(indexOn);
    let response: any;
    if (
      category == null &&
      country == null &&
      indexOn != 'retailers_highest_commission'
    ) {
      response = await documentRef.search(textToSearch, {
        attributesToRetrieve: attributesToRetrieve,
        hitsPerPage: limit,
        filters: `status:active`,
      });
    } else if (
      category != null &&
      country == null &&
      indexOn != 'retailers_highest_commission'
    ) {
      response = await documentRef.search(textToSearch, {
        attributesToRetrieve: attributesToRetrieve,
        hitsPerPage: limit,
        filters: `categories:${category} AND status:active`,
      });
    } else if (
      category == null &&
      country != null &&
      indexOn != 'retailers_highest_commission'
    ) {
      response = await documentRef.search(textToSearch, {
        attributesToRetrieve: attributesToRetrieve,
        hitsPerPage: limit,
        filters: `countries:${country} AND status:active`,
      });
    } else if (
      category != null &&
      country != null &&
      indexOn != 'retailers_highest_commission'
    ) {
      response = await documentRef.search(textToSearch, {
        attributesToRetrieve: attributesToRetrieve,
        hitsPerPage: limit,
        filters: `categories:${category} AND countries:${country} AND status:active`,
      });
    } else if (
      category == null &&
      country == null &&
      indexOn == 'retailers_highest_commission'
    ) {
      response = await documentRef.search(textToSearch, {
        attributesToRetrieve: attributesToRetrieve,
        hitsPerPage: limit,
        filters: `highestCommissionSearch:${commissionType} AND status:active`,
      });
    } else if (
      category != null &&
      country == null &&
      indexOn == 'retailers_highest_commission'
    ) {
      response = await documentRef.search(textToSearch, {
        attributesToRetrieve: attributesToRetrieve,
        hitsPerPage: limit,
        filters: `categories:${category} AND highestCommissionSearch:${commissionType} AND status:active`,
      });
    } else if (
      category == null &&
      country != null &&
      indexOn == 'retailers_highest_commission'
    ) {
      response = await documentRef.search(textToSearch, { 
        attributesToRetrieve: attributesToRetrieve,
        hitsPerPage: limit,
        filters: `countries:${country} AND highestCommissionSearch:${commissionType} AND status:active`,
      });
    } else if (
      category != null &&
      country != null &&
      indexOn == 'retailers_highest_commission'
    ) {
      response = await documentRef.search(textToSearch, {
        attributesToRetrieve: attributesToRetrieve,
        hitsPerPage: limit,
        filters: `categories:${category} AND countries:${country} AND highestCommissionSearch:${commissionType} AND status:active`,
      });
    }

    const retailers = new Array<RetailerCard>()
    
    response.hits.forEach(retailer => {

      if (retailer.status === 'active') {
        retailers.push({
          id: retailer.retailerId,
          logo: retailer.logo,
          logo_100x100: retailer.logo_100x100,
          image: retailer.image,
          image_600x200: retailer.image_600x200,
          displayName: retailer.displayName,
          highestCommissionType: retailer.highestCommissionType,
          highestCommissionAmount: retailer.highestCommissionAmount
        })
      }

    });

    return retailers;
  }

  async searchRetailersOnFirestore(
    documentIds: any,
    selectedCountry: any
  ): Promise<any> {
    let retailers = [];
    const country = selectedCountry == '' ? 'ALL' : selectedCountry;

    // Retailer Collection Reference
    const retailersRef = this.angularFirestore.collection(
      `retailers/${country}/retailers`
    ).ref;
    const itemRefs = documentIds.map((docId) => { 
      return retailersRef.doc(docId).get();
    });
    await Promise.all(itemRefs)
      .then(async (docs) => {
        retailers = await docs.map((doc: any) => {
          const docData = doc.data();
          docData.retailerId = doc.id;
          return docData;
        });
      })
      .catch((error) => console.log(error));
    return retailers;
  }

  // It will generate dynamic sorting way
  getSortingWay(selectedSort: string) {
    if (selectedSort == 'retailers_popularity') {
      // working
      return ['popularity', 'asc'];
    } else if (selectedSort == 'retailers_highest_commission$') {
      // working
      return ['highestCommissionAmount', 'desc'];
    } else if (selectedSort == 'retailers_highest_commission%') {
      // working
      return ['highestCommissionAmount', 'desc'];
    } else if (selectedSort == 'retailers_a_to_z') {
      // working
      return ['displayNameLower', 'asc'];
    } else if (selectedSort == 'retailers_z_to_a') {
      // working
      return ['displayNameLower', 'desc'];
    }
  }

  generateQuery(
    selectedCategory: string,
    sorting: string[],
    retailersRef: CollectionReference,
    commissionType: string
  ) {
    if (selectedCategory == '' && commissionType == null) {
      const query = retailersRef.orderBy(
        sorting[0],
        sorting[1] as firebase.firestore.OrderByDirection
      );
      return query;
    } else if (selectedCategory != '' && commissionType == null) {
      const query = retailersRef
        .where('categories', 'array-contains-any', [parseInt(selectedCategory)])
        .orderBy(sorting[0], sorting[1] as firebase.firestore.OrderByDirection);
      return query;
    } else if (selectedCategory != '' && commissionType != null) {
      const commission = commissionType === '%' ? '%' : '$'
      const query = retailersRef
        .where('categories', 'array-contains-any', [parseInt(selectedCategory)])
        .where('highestCommissionSearch', '==', commission)
        .orderBy(sorting[0], sorting[1] as firebase.firestore.OrderByDirection);
      return query;
    } else if (selectedCategory == '' && commissionType != null) {
      const commission = commissionType === '%' ? '%' : '$'
      const query = retailersRef
        .where('highestCommissionSearch', '==', commission)
        .orderBy(sorting[0], sorting[1] as firebase.firestore.OrderByDirection);
      return query;
    }
  }

  async getCountry() {

    let currentUser = JSON.parse(window.localStorage.getItem('user')) as any;

/*    const retailersRef = this.angularFirestore
      .collection(`users`)
      .doc(currentUser.uid).ref;
    const userCountry = window.sessionStorage.getItem('selectedCountry')
      ? window.sessionStorage.getItem('selectedCountry')
      : await retailersRef.get().then((snapshot) => {
          return snapshot.data().defaultCountry;
        });

    return userCountry;
    */
   return 'ALL'
  }

  checkAdBlocker() {
    let isBlocked;
    try {
      return fetch(
        new Request(
          'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
          {
            method: 'HEAD',
            mode: 'no-cors',
          }
        )
      )
        .then(function (response) {
          // Google Ads request succeeded, so likely no ad blocker
          isBlocked = false;
          return isBlocked;
        })
        .catch(function (e) {
          // Request failed, likely due to ad blocker
          isBlocked = true;
          return isBlocked;
        });
    } catch (error) {
      // fetch API error; possible fetch not supported (old browser)
      // Marking as a blocker since there was an error and so
      // we can prevent continued requests when this function is run
      isBlocked = true;
      return isBlocked;
    }
  }
  async getVisitedShops() {
    let visitedShopsData = [];
    
    const currentUser = JSON.parse(window.localStorage.getItem('user'));
    const usersRef = this.angularFirestore
      .collection(`users`)
      .doc(currentUser.uid).ref;
    await usersRef.get().then(async (doc) => {
      if (doc.data().recentRetailers) {
        await doc.data().recentRetailers.forEach(async (element) => {
          //  retailerIds.push(element.retailerId);
          let retailersRef = this.angularFirestore
            .collection(`retailers`)
            .doc(element.retailerId).ref;
          await retailersRef.get().then((document) => {
            if (document.exists) {
              let clicks = document.data();
              clicks.retailerId = document.id;
              visitedShopsData.push(clicks);
            }
          });
        });
      }
    });
    return visitedShopsData;
  }
  async getFAQ() {
    let faqData = [];
    const faqRef = this.angularFirestore.collection(`faq`).ref;
    const query = faqRef.orderBy('index', 'desc');
    await query.get().then((snapshot) => {
      snapshot.forEach(function (doc) {
        let faq = doc.data();
        faq.id = doc.id;
        faqData.push(faq);
      });
    });
    return faqData;
  }

  async getCurrencies() {
    await this.remoteConfig.fetchAndActivate();
    const res = await this.remoteConfig.getString('currencies');
    let obj = JSON.parse(res);
    let currencies = [];
    for (var i in obj) {
      currencies.push(obj[i]);
    }
    return currencies[0];
  }

  async getMyRewards() {
    let rewardsData = [];
    const currentUser = JSON.parse(window.localStorage.getItem('user'));
    const rewardsRef = this.angularFirestore.collection(`clicks`).ref;
    const query = rewardsRef
      .where('userId', '==', currentUser.uid)
      .where('type', '==', 'cashback')
      .orderBy('createdAt', 'desc');
    await query.get().then(async (snap) => {
      snap.forEach(function (doc) {
        let myRewards = doc.data();
        myRewards.rewardId = doc.id;
        rewardsData.push(myRewards);
      });
    });

    return rewardsData;
  }
}
