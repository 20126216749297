import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { FxService } from 'src/app/shared/services/fx.service';
import { SurveyService } from 'src/app/shared/services/survey.service';
import { CurrencyCode } from 'src/app/shared/types';
import { BitLabsSurveyInfoModel } from '../models/bitlabs.model';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Platform } from '@ionic/angular';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AuthService } from 'src/app/shared/services/auth.service';

declare const window: any;

@Component({
  selector: 'app-survey-card',
  templateUrl: './survey-card.component.html',
  styleUrls: ['./survey-card.component.scss'],
  animations: [
    trigger('hover', [
      state('up', style({ transform: 'translateY(-5px)' })),
      state('down', style({ transform: 'translateY(0%)' })),
      transition('up => down', animate('50ms ease-in')),
      transition('down => up', animate('50ms ease-out')),
    ]),
  ],
})
export class SurveyCardComponent implements OnInit {
  @Input() survey: BitLabsSurveyInfoModel;
  private usdExchange: number;
  private rates = null;
  public hoverState = 'down';

  constructor(
    private surveyService: SurveyService,
    private fxService: FxService,
    private config: AngularFireRemoteConfig,
    private afsAnalytics: AngularFireAnalytics,
    private authService: AuthService,
    public platform: Platform,
    private router: Router
  ) {
    this.config.fetchAndActivate().then(async () => {
      this.usdExchange = await this.config.getNumber('user_survey_share_usd');

      this.fxService.isReadyObservable.subscribe((ready) => {
        if (ready) {
          this.fxService.bitcoinRatesObservable.subscribe((rates) => {
            this.rates = rates;
          });
        }
      });
    });
  }

  ngOnInit() {}

  get name(): string {
    if (this.survey.details.network.name) {
      return this.survey.details.network.name;
    }
    return 'Survey';
  }

  get satsPayable(): string {
    if (this.rates) {
      return `${Math.round(
        (this.usdPayable / this.rates[CurrencyCode.USD]) * 100000000
      )}`;
    }
    return '0';
  }

  get usdPayable(): number {
    return parseFloat(this.survey.cpi) * this.usdExchange;
  }

  get time(): number {
    return this.survey.loi;
  }

  get category(): string {
    return this.survey.details.category.name;
  }

  get difficulty(): string {
    const diff = this.survey.details.difficulty.toLowerCase();
    return diff.charAt(0).toUpperCase() + diff.slice(1);
  }

  start() {
    const surveyData = JSON.stringify(this.survey);
    const user = JSON.parse(localStorage.getItem('user'));
    window.localStorage.setItem('surveyData', surveyData);
    if (this.platform.is('capacitor')) {
      this.surveyService
        .surveyClicked(this.survey, user.stsTokenManager.accessToken)
        .then((response) => {
          const clickId = response.data.data.clickId;
          this.surveyService
            .surveyStarted(this.survey.id, clickId, user.stsTokenManager.accessToken)
            .then((response) => {

              this.afsAnalytics.logEvent('survey_click', {
                network: 'bitlabs',
                name: this.name,
                sats: this.satsPayable,
                usd: this.usdPayable,
                time: this.time,
                category: this.category,
                difficulty: this.difficulty,
                rating: this.survey.rating,
                clickId: clickId,
                surveyId: this.survey.id,
                userId: this.authService.userId
              })
              .catch(error => console.error(error))

              const rewardId = response.data.data.rewardId;
              const link = `${this.survey.link}&tags=rewardId%3D${rewardId}`;
              this.router.navigate(['/survey-redirect-mob'], {
                queryParams: {
                  surveyLink: link,
                  surveyTitle: this.survey.details.network.name,
                },
              });
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    } else {
      
      this.afsAnalytics.logEvent('survey_click', {
        network: 'bitlabs',
        name: this.name,
        sats: this.satsPayable,
        usd: this.usdPayable,
        time: this.time,
        category: this.category,
        difficulty: this.difficulty,
        rating: this.survey.rating,
        surveyId: this.survey.id,
        userId: this.authService.userId
      })
      .catch(error => console.error(error))

      window.open(`${window.origin}/survey-redirect`, '_blank');
    }
    this.surveyService.removeSurvey(this.survey.id);
  }

  starColor(i: number): string {
    if (i <= this.survey.rating) return 'assets/images/star-yellow.svg';
    return 'assets/images/star-grey.svg';
  }

  mouseEnter() {
    this.hoverState = 'up';
  }

  mouseLeave() {
    this.hoverState = 'down';
  }
}
