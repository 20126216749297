import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/auth.service';
import axios from 'axios'
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss']
})
export class WithdrawComponent implements OnInit, OnDestroy {

  public lnurl = null
  public qr = null
  public sats = null
  public usd = null
  private transactionId = null
  private expiry = null
  public error = null
  private transactionSubscription: Subscription
  public status: string = null

  constructor(
    private firestore: AngularFirestore,
    private afsAnalytics: AngularFireAnalytics,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<WithdrawComponent>) {}

  ngOnInit(): void {
    this.authService.afAuth.currentUser.then(user => {
      user.getIdToken().then(token => {
        axios({
          method: 'post',
          url: `${environment.satsyRewardsBaseUrl}/v0/rewards/withdraw/sats`,
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        })
        .then(response => {

          this.lnurl = response.data.data.lnurl  
          this.qr = response.data.data.qr
          this.sats = response.data.data.sats
          this.usd = response.data.data.usd
          this.transactionId = response.data.data.transactionId
          this.expiry = response.data.data.expiry

          this.transactionSubscription = this.firestore.doc(`transactions/${this.transactionId}`)
          .snapshotChanges().subscribe(snapshot => {
            const transactionDoc = snapshot.payload.data() as any
            this.status = transactionDoc.status

            if (this.status === 'withdrawn') {
              this.afsAnalytics.logEvent('withdrawal_success', {
                sats: transactionDoc.sats
              })
            }

            if (this.status && this.status !== 'pending' 
            && this.status !== 'available'
            && this.status !== 'payment_success'
            && this.status !== 'withdrawn') {
              this.error = 'Withdrawal failed. Please contact customer support - hello@satsy.com'
              this.afsAnalytics.logEvent('withdrawal_error', {
                error: transactionDoc.status
              })
            }
            else {
              this.error = null
            }
          })
        })
        .catch(error => {
          console.error(error)
          this.error = 'Withdrawal failed. Please contact customer support - hello@satsy.com'
        })
      })
    })
  }

  ngOnDestroy(): void {
    this.transactionSubscription?.unsubscribe()
  }

  close() {
    this.dialogRef.close()
  }

  croppedLNURL(lnurl: string) : string {
    if (lnurl) {
      if (lnurl.length <= 18) {
        return lnurl
      }
      return lnurl.substring(0, 18) + '...'
    }
    return ''
  }

  snack() : void {
    this._snackBar.open('Copied!', 'Dismiss', {
      duration: 1500,
    });
  }

  openWallet(): void {
    window.open(`lightning:${this.lnurl}`, '_blank')
  }

  get countdown() : string {
    return '123'
  }
}