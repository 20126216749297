import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import axios from 'axios';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-join-card',
  templateUrl: './join-card.component.html',
  styleUrls: ['./join-card.component.scss'],
})
export class JoinCardComponent implements OnInit {
  @ViewChild('userName') username;
  @ViewChild('userPassword') password;

  public loading = false;
  public error: string = null;

  constructor(
    public fs: AngularFirestore,
    private afsAnalytics: AngularFireAnalytics,
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationError) {
        this.loading = false;
        this.error = null;
      }
    });
  }

  join() {

    this.afsAnalytics.logEvent('join_button_clicked')
    this.loading = true;
    this.error = null;
    this.authService
      .signUp(
        this.username.nativeElement.value,
        this.password.nativeElement.value
      )
      .then(() => {
        this.ngZone.run(async () => {
          
          this.afsAnalytics.logEvent('join_success')

          // Get the ip of the user
          try {
            const ipResponse = await axios({
              method: 'get',
              url: `https://pro.ip-api.com/json/?fields=countryCode&key=GgKgGMrjjsakGGZ`,
            });

            let code = ipResponse.data.countryCode;
            if (code === 'GB') {
              code = 'UK';
            }

            await this.fs
              .doc(`users/${this.authService.userId}`)
              .set({ 
                defaultCountry: code
              },  {merge: true});
          } catch (error) {
            console.error(error);
          }
        })
      })
      .catch((error) => {
        this.afsAnalytics.logEvent('join_error', {
          error: error.message
        })

        this.loading = false;
        this.error = error.message;
      });
  }
}
