import { Component, Inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ScreensizeService } from 'src/app/shared/services/screensize.service';

@Component({
  selector: 'app-dialog-profile',
  templateUrl: './dialog-profile.html',
  styleUrls: ['./dialog-profile.scss'],
})
export class DialogProfile {
  textFieldValue: string = '';
  public isDesktop = false;
  countries: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<DialogProfile>,
    @Inject(MAT_DIALOG_DATA) public userData: any,
    public toastController: ToastController,
    private authService: AuthService,
    private screensizeService: ScreensizeService,
    private apiService: ApiService,
    private afsAnalytics: AngularFireAnalytics
  ) {
    this.screensizeService.isDesktopView().subscribe((isDesktop) => {
      this.isDesktop = ScreensizeService.switchToDesktop(
        this.isDesktop,
        isDesktop
      );
    });
    if (userData.key == 'Date Of Birth' && userData.value.length) {
      this.textFieldValue = moment(userData.value, 'DD MMM, YYYY').format();
    } else if (userData.key == 'Country') {
      this.getCountries();
    } else if (userData.key == 'Password') {
      this.textFieldValue = '**********';
    } else {
      this.textFieldValue = userData.value;
    }
  }
  async getCountries() {
    this.countries = await this.apiService.getSupportedCountries();
    this.textFieldValue = this.userData.value;
  }

  changeCountry(e) {
    this.textFieldValue = e.detail.value;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  submitUserData() {
    if (
      this.userData.key == 'Email' &&
      this.textFieldValue ==
        JSON.parse(window.localStorage.getItem('user')).email
    ) {
      this.dialogRef.close();
      this.presentToast(`danger`, `Same Email Can't Be Updated`);
      return;
    }

    this.afsAnalytics.logEvent(`${this.userData.key.toLowerCase()}_updated`, {
      placement: 'profile_dialog'
    })

    this.authService.setProfile(this.textFieldValue, this.userData);
    this.dialogRef.close();
    this.presentToast(`primary`, `${this.userData.key} Has Been Changed`);
  }
  async presentToast(color, msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: color,
    });
    toast.present();
  }
  removeItem() {
    this.authService.removeProfileItem(this.textFieldValue, this.userData);
    this.dialogRef.close();
    this.presentToast(`danger`, `${this.userData.key} Has Been Removed`);
  }
}
