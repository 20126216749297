import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SurveyService, SurveyState } from 'src/app/shared/services/survey.service';
import { SurveyMessage } from '../survey-message/survey-message.component';

declare const window: any;

enum QualificationState {
  Begin,
  Questions,
  Finish,
  Problem
}

@Component({
  selector: 'app-qualification-survey',
  templateUrl: './qualification-survey.component.html',
  styleUrls: ['./qualification-survey.component.scss'],
})
export class QualificationSurveyComponent implements OnInit {

  QualificationState: typeof QualificationState = QualificationState
  public loading = true
  public state: QualificationState = QualificationState.Begin
  public problemType = null
  public message: SurveyMessage = null

  private startMessage: SurveyMessage = {
    header: 'Answer some questions for more surveys!',
    message: 'By answering the following questions, we can search our network for surveys that match you. Resulting in fewer screen outs.',
    button: 'Begin',
    action: () => {
      if (this.surveyService.hasQualificationQuestion()) {
        this.state = QualificationState.Questions 
      }
      else {
        this.setState(QualificationState.Finish)
      }
    }
  }

  private finishMessage: SurveyMessage = {
    header: 'Finished',
    message: 'Great! Head back to Satsy to see what new surveys are available to you.',
    button: 'Close',
    action: () => { window.close() }
  }

  private noQuestionsMessage: SurveyMessage = {
    header: 'No Questions',
    message: 'There are no qualification questions for you right now. Please try again later.',
    button: 'Close',
    action: () => { window.close() }
  }

  constructor(
    private authService: AuthService,
    private surveyService: SurveyService) {


  }

  async ngOnInit(): Promise<void> {
    try {    
      // window.Intercom('trackEvent', 'qualification-survey-opened')
      await this.checkForProblems()
      await this.surveyService.refreshSurveys(this.authService.userId)
      if (this.surveyService.hasQualificationQuestion()) {
        this.message = this.startMessage
      }
      else {
        this.message = this.noQuestionsMessage
      }
    }
    catch (error) {
      console.error(error)
    }  
    this.loading = false  
  }

  setState(state: QualificationState): void {
    this.state = state

    switch (state) {
      case QualificationState.Begin:
        this.message = this.startMessage
        break;
        
      case QualificationState.Finish:
        this.message = this.finishMessage
        break;
        
      default:
        break;
    }
  }

  async checkForProblems() {
    try {
      const surveyState =  await this.surveyService.surveyState(this.authService.userId)

      if (surveyState === SurveyState.Ok) {
         this.problemType = null
      }
      else if (surveyState === SurveyState.BannedUntil) {
        this.problemType = 'banned'
        this.state = QualificationState.Problem
      }          
      else if (surveyState === SurveyState.VPN) {
        this.problemType = 'vpn'
        this.state = QualificationState.Problem
      }         
      else if (surveyState === SurveyState.UnsupportedCountry) {
        this.problemType = 'unsupportedcountry'
        this.state = QualificationState.Problem
      }          
      else if (surveyState === SurveyState.Error) {
        this.problemType = 'error'
        this.state = QualificationState.Problem
      }

    
   }
   catch (error) {
      console.error(error)
      return SurveyState.Error
   }
  }

  finished(): void {
    this.setState(QualificationState.Finish)
  }
}
