import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-verify-card',
  templateUrl: './verify-card.component.html',
  styleUrls: ['./verify-card.component.scss']
})
export class VerifyCardComponent implements OnInit {

  public loading = false
  public error = null
  public verifyMessage = 'Resend verification email'
  private emailSent = false

  constructor(
    private router: Router,
    private authService: AuthService) { }

  ngOnInit(): void {
  }

  enter(): void {
    this.loading = true
    this.error = null

    this.authService.reloadUser().then( ()=> {
      this.authService.afAuth.currentUser.then( user => {
        if (user.emailVerified) {
          this.router.navigate([''])

        }
        else {
          this.error = 'You have not verified yet. Try resending the verification email.'
        }
        this.loading = false
     }) 
    })
    .catch(error => {
      console.error(error)
      this.error = error.message 
      this.loading = false
    })
  }

  verify(): void {
    if (!this.emailSent) {
      this.authService.sendVerificationMail()
      .catch(error => console.error(error))
    }
    this.verifyMessage = 'Email sent!'
    this.emailSent = true
  }

  backToSignIn(): void {
    this.authService.signOut()
    this.router.navigate(['sign-in'])
  } 
}
