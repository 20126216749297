import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CurrencyCode } from '../types';
import { AuthService } from './auth.service';
import { FxService } from './fx.service';

interface Balance {
  amount: number
  currency: CurrencyCode
}

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  public pendingSats:BehaviorSubject<number> = new BehaviorSubject(0)
  public readonly pendingSatsObservable: Observable<number> = this.pendingSats.asObservable()

  public unavailableSats:BehaviorSubject<number> = new BehaviorSubject(0)
  public readonly unavailableSatsObservable: Observable<number> = this.unavailableSats.asObservable()

  public availableSats:BehaviorSubject<number> = new BehaviorSubject(0)
  public readonly availableSatsObservable: Observable<number> = this.availableSats.asObservable()

  public withdrawnSats:BehaviorSubject<number> = new BehaviorSubject(0)
  public readonly withdrawnSatsObservable: Observable<number> = this.withdrawnSats.asObservable()

  public pendingUSD:BehaviorSubject<number> = new BehaviorSubject(0)
  public readonly pendingUSDObservable: Observable<number> = this.pendingUSD.asObservable()

  public unavailableUSD:BehaviorSubject<number> = new BehaviorSubject(0)
  public readonly unavailableUSDObservable: Observable<number> = this.unavailableUSD.asObservable()

  public availableUSD:BehaviorSubject<number> = new BehaviorSubject(0)
  public readonly availableUSDObservable: Observable<number> = this.availableUSD.asObservable()


  private rates = null
  private pending: Array<Balance> = null
  private unavailable: Array<Balance> = null
  private available: Array<Balance> = null
  private withdrawn: Array<Balance> = null

  constructor(
    private fxService: FxService,
    public fs: AngularFirestore,
    public authService: AuthService) { 

    this.fxService.isReadyObservable.subscribe(ready => {
      if (ready) {
        this.fxService.bitcoinRatesObservable.subscribe(rates => {
          this.rates = rates
          this.updateBehaviour(this.rates, this.pending, this.pendingSats, this.pendingUSD)
          this.updateBehaviour(this.rates, this.unavailable, this.unavailableSats, this.unavailableUSD)
          this.updateBehaviour(this.rates, this.available, this.availableSats, this.availableUSD)
        })
      }
    })

    this.fs.collection(`rewards`, ref => ref
      .where('userId', '==', this.authService.userId)
      .where("status", "in", ["pending"]))
      .valueChanges().subscribe(snapshot => {
      this.pending = new Array<Balance>()
      snapshot.forEach((doc: any) => {
        this.pending.push({
          amount: doc.payout.user,
          currency: doc.payout.currency.toUpperCase()
        })
      })
      this.updateBehaviour(this.rates, this.pending, this.pendingSats, this.pendingUSD)
    })

    this.fs.collection(`rewards`, ref => ref
      .where('userId', '==', this.authService.userId)
      .where("status", "in", ["available", "withdraw_failed", "processing"])
      // .orderBy('availableAt')
      // .startAfter(now))
      ).valueChanges().subscribe(snapshot => {
        
        this.unavailable = new Array<Balance>()
        snapshot.forEach((doc: any) => {
          if (doc.availableAt.toDate() > new Date()) {
            this.unavailable.push({
              amount: doc.payout.user,
              currency: doc.payout.currency.toUpperCase()
            })
          }
        })
        this.updateBehaviour(this.rates, this.unavailable, this.unavailableSats, this.unavailableUSD)
    })

    this.fs.collection(`rewards`, ref => ref.where('userId', '==', this.authService.userId)
      .where("status", "in", ["available", "withdraw_failed", "processing"])
      // .orderBy('availableAt', 'desc')
      // .startAfter(now)
      ).valueChanges().subscribe(snapshot => {
      this.available = new Array<Balance>()
      snapshot.forEach((doc: any) => {
        if (doc.availableAt.toDate() <= new Date()) {
          this.available.push({
            amount: doc.payout.user,
            currency: doc.payout.currency.toUpperCase()
          })
        }
      })
      this.updateBehaviour(this.rates, this.available, this.availableSats, this.availableUSD)
    })

    
    this.fs.collection(`rewards`, ref => ref.where('userId', '==', this.authService.userId)
      .where("status", "in", ["available", "withdraw_failed", "processing"])
      ).valueChanges().subscribe(snapshot => {
      this.available = new Array<Balance>()
      snapshot.forEach((doc: any) => {
        if (doc.availableAt.toDate() <= new Date()) {
          this.available.push({
            amount: doc.payout.user,
            currency: doc.payout.currency.toUpperCase()
          })
        }
      })
      this.updateBehaviour(this.rates, this.available, this.availableSats, this.availableUSD)
    })


    this.fs.doc(`users/${this.authService.userId}`)
    .snapshotChanges().subscribe(doc => {
      const userDoc = doc.payload.data() as any
      console.log(userDoc.withdrawnSats)
      this.withdrawnSats.next(userDoc.withdrawnSats)
    })

  }

  updateBehaviour(rates: any, balances: Array<Balance>, behaviorSats: BehaviorSubject<number>, behaviorFiat: BehaviorSubject<number>) {
    if (rates && balances) {
      let sats = 0
      balances.forEach(balance => {
        sats += Math.round(balance.amount / rates[balance.currency] * 100000000)
      })
      behaviorSats.next(sats)
      const fiat = sats / 100000000 * rates[CurrencyCode.USD]

      behaviorFiat.next(fiat)
    }
  }

}
