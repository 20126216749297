import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RetailerCard } from '../types';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingSearchService {

  public isReady:BehaviorSubject<boolean> = new BehaviorSubject(false)
  public readonly isReadyObservable: Observable<boolean> = this.isReady.asObservable()

  public isSearching:BehaviorSubject<boolean> = new BehaviorSubject(true)
  public readonly isSearchingObservable: Observable<boolean> = this.isSearching.asObservable()

  public shops:BehaviorSubject<Array<RetailerCard>> = new BehaviorSubject(null)
  public readonly shopsObservable: Observable<Array<RetailerCard>> = this.shops.asObservable()
  
  private shopsCache = new Array<RetailerCard>()
  private countries = new Array<string>()
  private categories = new Array<string>()
  private shopsPerLoad: number = 24
  private selectedCountry = 'ALL'
  private selectedCategory = 'All'
  private selectedSort = 'retailers_popularity'
  private searchText = ''

  constructor(
    private authService: AuthService,
    private apiService: ApiService) {

    this.authService.afAuth.onAuthStateChanged(user => {
      if(user) {
        const countriesPromise = this.apiService.getSupportedCountries()
        const categoriesPromise = this.apiService.getRetailersCategories()
        const countryPromise = this.apiService.getCountry()
    
        Promise.all([
          countriesPromise,
          categoriesPromise,
          countryPromise
        ])
        .then(values => {
          this.countries = values[0]
          this.categories = values[1]
          this.selectedCountry = values[2]
  
          // Check if selected country is supported, if not pick US
          const findMatch = (element) => { 
            return element.countryCode === this.selectedCountry
          }
          if (this.countries.findIndex(findMatch) === -1) {
            this.selectedCountry = 'ALL'
            window.sessionStorage.setItem('selectedCountry', 'ALL');
          }
          // Search for all the retailers based on these initial results
          this.search()
          .catch(error => console.error(error))
        })
        .catch(error => console.error(error))
      }
    })
  }

  setSearchText(text: string) {
    this.searchText = text
  }

  getSearchText() : string {
    return this.searchText
  }

  setSort(sort: string) {
    this.selectedSort = sort
  }

  getSort(): string {
    return this.selectedSort
  }

  setCategory(category: string) {
    this.selectedCategory = category
  }

  getCategory(): string {
    return this.selectedCategory
  }

  getCategories(): Array<string> {
    return this.categories
  }

  setCountry(country: string) {
    this.selectedCountry = country;
    window.sessionStorage.setItem('selectedCountry', this.selectedCountry);
  }

  getCountry(): string {
    return this.selectedCountry
  }

  getCountries(): Array<string> {
    return this.countries
  }

  loadMoreShops() {
    this.shopsPerLoad += 16;
    this.shops.next(this.shopsCache.slice(0, this.searchMax()))
  }

  async search() {
    this.isSearching.next(true)
    this.shopsPerLoad = 24;

    await this.onSearch(this.searchText)
    this.isSearching.next(false)

    if (!this.isReady.value) {
      this.isReady.next(true)
    }
  }



  async onSearch(textToSearch: string) {
    try {
      const searchedData = await this.apiService.searchRetailers(
        textToSearch,
        this.selectedCountry,
        this.selectedCategory,
        this.selectedSort,
        500
      );

      if (searchedData.length) {
        this.shopsCache = searchedData
        this.shops.next(searchedData.slice(0, this.searchMax()))
      } 
      else {
        this.shops.next(new Array<any>())
      }
    } catch (error) {
      console.log(error);
    }
  }

  searchMax(): number {
    if (this.shopsCache.length < this.shopsPerLoad) {
      return this.shopsCache.length
    }
    return this.shopsPerLoad
  }

  canLoadMoreShops(): boolean {
    return this.shopsCache.length > this.shopsPerLoad
  }
}
