import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-error',
  templateUrl: './survey-error.component.html',
  styleUrls: ['./survey-error.component.scss']
})
export class SurveyErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  close(): void {
    window.close()
  }
}
