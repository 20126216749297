import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-forgot-password-card',
  templateUrl: './forgot-password-card.component.html',
  styleUrls: ['./forgot-password-card.component.scss']
})
export class ForgotPasswordCardComponent implements OnInit {
  @ViewChild('userName') username; 

  public message: string = null
  constructor(
    private authService: AuthService,
    private router: Router) 
  { }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      // if (event instanceof NavigationStart) {
      //     // Show loading indicator
      // }

      if (event instanceof NavigationEnd ||
          event instanceof NavigationError) {
        this.message = null
      }
    })
  }

  reset() {
    this.message = 'Password reminder sent!'
    this.authService.forgotPassword(this.username.nativeElement.value)
  }
}
