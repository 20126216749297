import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  message: string = '';
  forename: string = '';
  surname: string = '';
  subject: string = '';
  submitted: boolean = false;
  showSpinner: boolean = false;

  constructor(private functions: AngularFireFunctions) {}

  ngOnInit() {}

  sendMessage() {
    this.showSpinner = true;
    const callable = this.functions.httpsCallable('supportRequest');
    callable({
      forename: this.forename,
      surname: this.surname,
      subject: this.subject,
      message: this.message,
    }).subscribe((data) => {
      this.submitted = true;
      this.showSpinner = false;
    });
  }
}
