import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations'
import { WalletService } from 'src/app/shared/services/wallet.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [
    trigger('menuState', [
      state('show', style({transform: 'translateY(0%)'})),
      state('hide', style({transform: 'translateY(-100%)'})),
      transition('show => hide', animate('250ms ease-in')),
      transition('hide => show', animate('250ms ease-out'))
    ]),
    trigger('backgroundState', [
      state('show', style({opacity: 1})),
      state('hide', style({opacity: 0})),
      transition('show => hide', animate('250ms ease-in')),
      transition('hide => show', animate('250ms ease-out'))
    ])
  ]
})
export class MobileMenuComponent implements OnInit {

  @Input() show = false
  @Output() hidden = new EventEmitter<void>()

  public availableUSD = 0
  public availableSats = 0

  constructor(
    private authService: AuthService,
    private walletService: WalletService,
    private afsAnalytics: AngularFireAnalytics,
    private router: Router) { }

  ngOnInit() {
    this.walletService.availableSatsObservable.subscribe(available => {
      this.availableSats = available
    })

    this.walletService.availableUSDObservable.subscribe(available => {
      this.availableUSD = available
    })
  }

  get stateName() {
    return this.show ? 'show' : 'hide'
  }

  hide() {
    this.show = false
    this.hidden.emit()
  }

  signOut() {
    this.hide()
    this.afsAnalytics.logEvent('signout', {
      placement: 'navigation'
    })
    this.authService.signOut()
  }

  goToRoute(route: string, event: string) {
    this.afsAnalytics.logEvent(event, {
      placement: 'navigation'
    })
    this.router.navigateByUrl(route)
  }
}
