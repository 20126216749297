import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  
  constructor(
    public authService: AuthService,
    public router: Router
  ){ }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
      /*if (!this.authService.isLoggedIn) {
        this.router.navigate(['sign-in'])
        return false
      }*/

      return new Promise<boolean>( 
        async (resolve, reject) => {
          try {
            if (!this.authService.isLoggedIn) {
              this.router.navigate(['sign-in'])
              resolve(false)
            }

            const user = await this.authService.afAuth.currentUser
            if (this.authService.isVerified || user.emailVerified) {
              resolve(true)
            }
            else { 
              this.router.navigate(['sign-in/verify'])
              resolve(false)
            }
          }
          catch (error) {
            this.router.navigate(['sign-in'])
            reject(error)
          }
    })
  }

  async isVerified() {
    try {
      const user = await this.authService.afAuth.currentUser
      return user.emailVerified
    }
    catch (error) {
      return false
    }
  }
}