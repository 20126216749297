import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScreensizeService {
  private mobileThreshold = 992; // pixel width
  private isDesktop = new BehaviorSubject<boolean>(true);

  constructor() {
    this.isDesktop.next(window.innerWidth > this.mobileThreshold);
  }

  get isCurrentlyDesktop() {
    return window?.innerWidth < this.mobileThreshold;
  }

  onResize(size: any) {
    if (size < this.mobileThreshold) {
      this.isDesktop.next(false);
    } else {
      this.isDesktop.next(true);
    }
  }

  isDesktopView(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }

  static switchToDesktop(previous: boolean, current: boolean): boolean {
    if (previous && !current) {
      window.location.reload();
    }
    return current;
  }
}
