import { Injectable } from '@angular/core';
import axios from 'axios'
import { isMobile, isMobileOnly, isTablet } from 'mobile-device-detect';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { BitLabsQuestionModel, BitLabsSurveyInfoModel, BitLabsSurveyModel } from '../../components/surveys/models/bitlabs.model';

export enum SurveyDifficulty {
  Recommended = 'RECOMMENDED',
  Easy = 'EASY',
  Medium = 'MEDIUM',
  Hard = 'HARD'
}

export enum SurveyState {
  VPN = 0,
  BannedUntil = 1,
  UnsupportedCountry = 2,
  Ok = 3,
  Error = 4
}

@Injectable({
  providedIn: 'root'
})
export class SurveyService  {
  
  private MAX_RECOMMENDED = 9

  public surveysExist:BehaviorSubject<boolean> = new BehaviorSubject(false)
  public readonly surveysExistObservable: Observable<boolean> = this.surveysExist.asObservable()

  public surveyList:BehaviorSubject< Array<BitLabsSurveyInfoModel> > = new BehaviorSubject(null)
  public readonly surveyListObservable: Observable< Array<BitLabsSurveyInfoModel> > = this.surveyList.asObservable()

  private api = 'https://api.bitlabs.ai/v1'
  private bitlabs: BitLabsSurveyModel
  private error: any
  private _surveyState = SurveyState.Ok

  constructor(private authService: AuthService) {
    this.authService.afAuth.authState.subscribe( (user) =>{
      if (user) {

      }
      else {
        this.bitlabs = null
        this.error = null
      }
    })
  }

  async surveyState(userId: string) {
    if (!this.bitlabs || this.bitlabs?.data?.surveys?.length === 0) {
      await this.refreshSurveys(userId)
    }
    return this._surveyState
  }

  async refreshSurveys(userId: string) {
    try {
      const surveys = await axios({
        method: 'get',
        url: `${this.api}/client/actions?platform=${this.platformType()}`,
        headers: {
          'X-Api-Token': environment.bitlabsApiKey,
          'X-User-Id': userId
        }
      });

      this.bitlabs = surveys.data
      
      if (this.bitlabs?.data?.surveys?.length > 0) {
        this.surveysExist.next(true)
        this.surveyList.next(this.bitlabs?.data?.surveys?.slice(0, this.MAX_RECOMMENDED))
      }
      else {
        this.surveysExist.next(false)
        this.surveyList.next(null)
      }

      this.setSurveyState(this.bitlabs)
      this.error = null;
    } catch (error) {
      console.error(error);
      this.error = error;
      this._surveyState = SurveyState.Error
      this.bitlabs = null;
    }
  }

  setSurveyState(surveys: BitLabsSurveyModel) {
    if (surveys.data.restriction_reason?.using_vpn === true) {
      this._surveyState = SurveyState.VPN
    }
    else if (surveys.data.restriction_reason?.banned_until) {
      this._surveyState = SurveyState.BannedUntil
    }
    else if (surveys.data.restriction_reason?.unsupported_country) {
      this._surveyState = SurveyState.UnsupportedCountry
    }
    else {
      this._surveyState = SurveyState.Ok
    }
  }

  async answerQuestion(userId: string, question:BitLabsQuestionModel, answers: Array<string>, isSkipped: boolean) {



    try {

      // Add 'skip' to the end of the bitlabs api to skip the question 
      if (isSkipped) {
        await axios({
          method: 'post',
          url: `${this.api}/client/networks/${question.network_id}/questions/${question.question.id}/skip`,
          headers: {
            'X-Api-Token': environment.bitlabsApiKey,
            'X-User-Id': userId
          }
        })
      }
      else {
        await axios({
          method: 'post',
          url: `${this.api}/client/networks/${question.network_id}/questions/${question.question.id}`,
          headers: {
            'X-Api-Token': environment.bitlabsApiKey,
            'X-User-Id': userId
          },
          data: {
            answers: answers
          }
        })
      }

      const surveys = await axios({
        method: 'get',
        url: `${this.api}/client/actions?platform=${this.platformType()}`,
        headers: {
          'X-Api-Token': environment.bitlabsApiKey,
          'X-User-Id': userId
        }
      });
      this.bitlabs = surveys.data


      if (this.bitlabs?.data?.surveys?.length > 0) {
        this.surveysExist.next(true)
        this.surveyList.next(this.bitlabs?.data?.surveys?.slice(0, this.MAX_RECOMMENDED))
      }
      else {
        this.surveysExist.next(false)
        this.surveyList.next(null)
      }

      this.setSurveyState(this.bitlabs)
      this.error = null

      return {
        state: this._surveyState,
        question: question
      }
    } catch (error) {
      console.error(error)
      this.error = error
      this._surveyState = SurveyState.Error
      this.bitlabs = null

      return {
        state: SurveyState.Error,
        question: question,
        error: error
      }
    }
  }

  hasSurveys() : boolean {
    return this.surveyList.value?.length > 0
  }

  hasError() : boolean {
    return this.error ? true : false
  }

  isQualified() : boolean {
    return !this.bitlabs?.data?.qualification ||
     (!this.bitlabs?.data?.qualification.is_standard_profile && this.bitlabs?.data?.surveys?.length > 3)
  }

  hasQualificationQuestion() : boolean {
    return this.bitlabs?.data?.qualification?.question ? true : false
  }

  qualificationQuestion() : BitLabsQuestionModel {
    if (!this.hasQualificationQuestion()) {
      return null
    }
    return this.bitlabs?.data?.qualification
  }

  getSurveyLink(surveyId: string) : string {
    if (this.bitlabs?.data?.surveys?.length > 0) {
      return this.getSurvey(surveyId).link
    }
    return null
  }

  getBannedDate() {
    return new Date(this.bitlabs.data.restriction_reason.banned_until)
  }

  deleteUser(userId: string) {
    return axios.delete(`${this.api}/client/user`, {
      headers: {
        'X-Api-Token': environment.bitlabsApiKey,
        'X-User-Id': userId
      },
      data: {}
    })
  }

  removeSurvey(surveyId: string) {
    const surveys = this.surveyList.value

    const findId = (element) => { 
      return element.id === surveyId
    }
    const index = surveys.findIndex(findId)
    if (index > -1) {
      surveys.splice(index, 1)
    }

    this.surveyList.next(surveys)
  }

  getSurvey(surveyId: string) {
    return this.bitlabs?.data?.surveys.find(({ id }) => id === surveyId)
  }

  async surveyClicked(survey: BitLabsSurveyInfoModel, userToken: string) {
   
    if (!userToken) {
      console.error('Missing User Token. Required to initiate the survey')
    }
    
    return axios({
      method: 'post',
      url: `${environment.satsyApiBaseUrl}/v0/survey/status`,
      headers: {
        Authorization: `Bearer ${userToken}`
      },
      data: {
        status: 'clicked',
        surveyId: survey.id,
        data: survey
      }
    })
  }

  async surveyStarted(surveyId: string, clickId: string, userToken: string) {

    if (!userToken) {
      console.error('Missing User Token. Required to initiate the survey')
    }

    return axios({
      method: 'post',
      url: `${environment.satsyApiBaseUrl}/v0/survey/status`,
      headers: {
        Authorization: `Bearer ${userToken}`
      },
      data: {
        status: 'started',
        surveyId: surveyId,
        clickId: clickId
      }
    })
  }

  platformType(): string {
    if (isMobileOnly) {
      return 'MOBILE'
    }
    else if (isTablet) {
      return 'TABLET'
    }
    return 'WEB'
  }
}