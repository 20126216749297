import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  panelOpenState = false;
  faq: any;
  showSpinner: boolean = false;
  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.getFAQ();
  }
  async getFAQ() {
    this.showSpinner = true;
    const faqData = await this.apiService.getFAQ();
    this.faq = faqData;
    this.showSpinner = false;
  }
}
