import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ScreensizeService } from 'src/app/shared/services/screensize.service';
import { WalletService } from 'src/app/shared/services/wallet.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  title = '';
  public isDesktop = false;
  public availableUSD = 0;
  public availableSats = 0;
  public showBack = false;
  public showMenu = false;
  public previousPage = '';

  constructor(
    private walletService: WalletService,
    public authService: AuthService,
    private screensizeService: ScreensizeService,
    private navCtrl: NavController,
    private afsAnalytics: AngularFireAnalytics,
    private ngZone: NgZone,
    private router: Router
  ) {
    this.screensizeService.isDesktopView().subscribe((isDesktop) => {
      this.isDesktop = ScreensizeService.switchToDesktop(
        this.isDesktop,
        isDesktop
      );
    });

    this.walletService.availableSatsObservable.subscribe((available) => {
      this.availableSats = available;
    });

    this.walletService.availableUSDObservable.subscribe((available) => {
      this.availableUSD = available;
    });
  }

  ngOnInit(): void {
    this.previousPage = this.router.url;
    this.refreshBackArrowState(this.router.url);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.previousPage = this.router.url;
      }
      if (event instanceof NavigationEnd) {
        this.refreshBackArrowState(event.url);
      }
    });

    var splitted = this.router.url.split('/', 2);
    this.title = splitted[1];

    if (this.title === 'surveys') this.title = 'your surveys';

    this.router.events.subscribe((val) => {
      var splitted = this.router.url.split('/', 2);
      this.title = splitted[1];
      if (this.title === 'surveys') this.title = 'your surveys';
    });
  }

  refreshBackArrowState(url: string) {
    this.ngZone.run(() => {
      this.showBack =
        url.includes('/retailer') ||
        url === '/faq' ||
        url === '/missing-satsback' ||
        url === '/contact-us';
    });
  }

  signOut() {
    this.afsAnalytics.logEvent('signout', {
      placement: 'navigation'
    })
    this.authService.signOut();
  }

  back() {
    //this.navCtrl.back();
    if (this.previousPage === '/retailer') {
      this.navCtrl.navigateBack('home');
    } else if (this.previousPage === '/faq') {
      this.navCtrl.navigateBack('support');
    } else if (this.previousPage === '/contact-us') {
      this.navCtrl.navigateBack('support');
    } else if (this.previousPage === '/missing-satsback') {
      this.navCtrl.navigateBack('support');
    } else {
      this.navCtrl.navigateBack(`/${this.previousPage}`);
    }
  }

  toggle() {
    this.showMenu = !this.showMenu;
  }

  mobileMenuHidden() {
    this.showMenu = false;
  }

  goToRoute(route: string, event: string) {
    this.afsAnalytics.logEvent(event, {
      placement: 'navigation'
    })
    this.router.navigateByUrl(route)
  }
}
