import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { FxService } from 'src/app/shared/services/fx.service';
import { ScreensizeService } from 'src/app/shared/services/screensize.service';
import { capitalizeFirstLetter } from 'src/app/shared/utils';

@Component({
  selector: 'app-history-cell',
  templateUrl: './history-cell.component.html',
  styleUrls: ['./history-cell.component.scss'],
})
export class HistoryCellComponent implements OnInit {

  private _rewardId: string
  @Input() set rewardId(value: string) {
      this._rewardId = value

      this.rewardSubscription?.unsubscribe()
      this.rewardSubscription = this.fs.doc(`rewards/${value}`)
        .snapshotChanges().subscribe(snapshot => {
          this.reward = snapshot.payload.data()

          if (!this.reward?.withdrawnSats && this.reward?.status === 'withdrawn') {
            console.log(value)
            this.fs.doc(`transactions/${this.reward?.transactionId}`)
            .get()
            .toPromise()
            .then(doc => {
              this.satsAtWithdrawal = Math.round(doc.data().sats / doc.data().usd * this.reward.payout.user)
            })
          }
      })
 }

 private satsAtWithdrawal = 0
  private rates = null
  public isDesktop = false
  public reward: any
  private rewardSubscription: Subscription
  private transactionSubscription: Subscription

  constructor(   
    private screensizeService: ScreensizeService,
    private datePipe: DatePipe,
    private fxService: FxService,
    private fs: AngularFirestore) { }

  ngOnInit() {

    this.screensizeService.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = ScreensizeService.switchToDesktop(this.isDesktop, isDesktop)
    })

    this.fxService.isReadyObservable.subscribe(ready => {
      if (ready) {
        this.fxService.bitcoinRatesObservable.subscribe(rates => {
          this.rates = rates
        })
      }
    })
  }

  ngOnDestroy(){
    this.rewardSubscription?.unsubscribe()
    this.transactionSubscription?.unsubscribe()
  }

  satsAmount(): number { 
    if (this.rates) {
      if (this.reward?.status === 'withdrawn') {
        const sats = this.reward?.withdrawnSats
        if (sats) {
          return sats
        }
        else {
          return this.satsAtWithdrawal
        }
      }
      else {
        return Math.round(this.reward?.payout.user / this.rates[this.rewardCurrency()] * 100000000)
      }
    }
    return 0
  }

  fiatAmount(): number { 
    return this.reward?.payout.user
  }

  rewardCurrency(): string {
    return this.reward?.payout.currency.toUpperCase()
  }

  rewardStatus(): string {

    let status = this.reward?.status
    if (this.isRewardAvailable()) {
      status = 'payable'
      if (this.reward?.type === 'survey' && this.reward?.survey?.status === 'screenout_rewarded') {
        status = 'screenout bonus'
      }
    }
    else if (status === 'available' && !this.isRewardAvailable()) {
      status = 'pending'
    }
    else if(this.hasSurveyExpired()) {
      status = 'cancelled'
    }
    else if(this.reward?.type === 'survey' && this.reward?.status === 'rejected') {
      status = 'screenout'
    }
    status = capitalizeFirstLetter(status)
    return `${status}`
  }

  rewardCompany(): string {
    return `${this.reward.company}`
  }

  rewardDate(): string {
    const date = this.reward.modifiedAt.toDate() as Date
    const formattedDate = this.datePipe.transform(date)
    return `${formattedDate}`
  }

  rewardStyle(): any {
    if ((this.reward?.status !== 'available' &&
        this.reward?.status !== 'withdraw_failed' &&
        this.reward?.status !== 'processing')) {
      return {
         color: '#1c1b5642'
      }
    }

    return {
      color: '#1c1b56'
    }
  }

  rewardTitle(): string {
    if (this.reward?.type === 'survey') {
      return 'Survey'
    }
    else if (this.reward.type === 'credit') {
      return this.reward?.credit?.title
    }
    else if (this.reward?.type === 'cashback') {
      return 'Shopping'
    }

    console.error('Unsupported reward type')
    return 'Reward'
  }

  dotColor(): string {
    if (this.isRewardAvailable()) {
      return '#1ED98A'
    }
    else if (this.reward?.status === 'withdrawn') {
      return '#8646D9'
    }  
    else if(this.hasSurveyExpired()) {
      return '#666666'
    }
    else if (this.reward?.status === 'screenout' ||
            this.reward?.status === 'rejected' ||
            this.reward?.status === 'refunded' ||
            this.reward?.status === 'expired') {
      return '#FF4343'
    }
    return '#FFC656'
  }

  rewardIcon(): string {
    if (this.reward?.type === 'survey') {
      return 'assets/images/survey-icon.svg'
    }
    else if (this.reward?.type === 'cashback') {
      return 'assets/images/shopping-icon.svg'
    }
    return 'assets/images/gift-icon.svg'
  }

  isRewardAvailable() {
    return this.reward?.availableAt?.toDate() <= new Date() && 
      (this.reward?.status === 'available'||
      this.reward?.status === 'withdraw_failed'||
      this.reward?.status === 'processing')
  }

  hasSurveyExpired(): boolean {
    const timeout = this.reward?.modifiedAt.toDate() as Date
    return timeout < new Date() && this.reward?.type === 'survey' && this.reward?.status === 'started'
  }

  showAmount() {
    return !(this.rewardStatus() === 'Cancelled')
  }
}
