import { Component, OnInit } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { AngularFireRemoteConfig } from '@angular/fire/remote-config'
import { MatDialog } from '@angular/material/dialog'
import { AuthService } from 'src/app/shared/services/auth.service'
import { BitcoinRate } from 'src/app/shared/services/bitcoin-rate.service'
import { WalletService } from 'src/app/shared/services/wallet.service'
import { WithdrawComponent } from '../withdraw/withdraw.component'

@Component({
  selector: 'app-balance-card',
  templateUrl: './balance-card.component.html',
  styleUrls: ['./balance-card.component.scss'],
})
export class BalanceCardComponent implements OnInit {

  public isProcessing = false
  public showWithdrawButton = true
  private initialThresholdAlreadyMet = false
  public initialThresholdMet = true
  public initialThreshold = 0
  public isReady = false
  private _bitcoinPrice = 0
  public pendingSats = 0
  public withdrawnSats = 0
  public unavailableSats = 0
  public availableSats = 0
  public availableUSD = 0

  constructor(
    private config: AngularFireRemoteConfig,
    public fs: AngularFirestore,
    public authService: AuthService,
    private _walletService: WalletService,
    private _bitcoinRate: BitcoinRate,
    public dialog: MatDialog) { }

  ngOnInit() {
    this._bitcoinRate.dollarToSatoshiObservable.subscribe(rate => {
      if (rate > 0) {
        this._bitcoinPrice = rate
      }
    })

    this._walletService.pendingSatsObservable.subscribe(pending => {
      this.pendingSats = pending
      console.log(pending)
    })

    this._walletService.withdrawnSatsObservable.subscribe(withdrawn => {
      this.withdrawnSats = withdrawn
    })

    this._walletService.availableSatsObservable.subscribe(available => {
      this.availableSats = available
    })

    this._walletService.unavailableSatsObservable.subscribe(unavailable => {
      this.unavailableSats = unavailable
    })

    this._walletService.availableUSDObservable.subscribe(available => {
      this.availableUSD = available
      this.initialThresholdMet = this.initialThresholdAlreadyMet || this.initialThreshold < this.usdPayable
    })

    this.fs.doc(`users/${this.authService.userId}`).ref.get().then(async doc => {
      this.config.fetchAndActivate()
      .then(async () => {
        this.initialThresholdAlreadyMet = doc.data().initialThreshold
        this.initialThreshold = await this.config.getNumber('user_withdrawal_threshold_initial')
        this.initialThresholdMet = this.initialThresholdAlreadyMet || this.initialThreshold < this.usdPayable
        this.isReady = true
      })
    })
  }

  get bitcoinPrice(): number {
    return this._bitcoinPrice
  }

  get withdrawalButtonColor(): string {
    // if (this.zeroBalance || !this.initialThresholdMet)
    //   return 'medium'
    return 'accent'
  }

  get satsPayable(): number {
    return this.availableSats
  }

  get usdPayable(): number {
    return this.availableUSD
  }

  get pending(): number {
    return this.pendingSats + this.unavailableSats
  }

  get available(): number {
    return this.availableSats
  }

  get withdrawn(): number {
    return this.withdrawnSats
  }

  get zeroBalance(): boolean {
    return this.usdPayable === 0
  }

  withdraw() : void {
    this.showWithdrawButton = false
    this.isProcessing = true
    const dialogRef = this.dialog.open(WithdrawComponent, {
      width: '350px',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      this.isProcessing = false
      this.showWithdrawButton = true
      this.initialThresholdMet = true
      this.isReady = true
    })
  }
}
