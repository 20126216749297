import { NgZone } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";

enum Card {
  Signin = 'signin',
  Signup = 'signup',
  ForgotPassword = 'forgot',
  Verify  = 'verify'
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  @ViewChild('userName') username; 
  @ViewChild('userPassword') password; 

  public currentCard = Card.Signin
  public loading = false
  public error: string = null

  constructor(
    public authService: AuthService,
    public router: Router,  
    public ngZone: NgZone) { }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      // if (event instanceof NavigationStart) {
      //     // Show loading indicator
      // }

      if (event instanceof NavigationEnd ||
          event instanceof NavigationError) {
        this.setLoaded()
      }
  })
  }

  signIn() {
    this.setLoading()
    this.error = null
    this.authService.signIn(this.getEmail(), this.getPassword())
    .then( () => {
      this.ngZone.run(() => {
        this.router.navigate(['surveys'])
      })
    })
    .catch( error => {
      this.setLoaded()
      this.error = error.message
    })
  }

  forgotPassword() {
    this.authService.forgotPassword(this.getEmail())
    .then( () => {
      
    })
  }

  setCard(card: Card) {
    this.currentCard = card
  }

  setLoading() {
    this.loading = true
  }

  setLoaded() {
    this.loading = false
  }

  getEmail() {
    return this.username.nativeElement.value
  }

  getPassword() {
    return this.password.nativeElement.value
  }
}
