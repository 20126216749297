import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import axios from 'axios';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-sign-in-card',
  templateUrl: './sign-in-card.component.html',
  styleUrls: ['./sign-in-card.component.scss'],
})
export class SignInCardComponent implements OnInit {
  @ViewChild('userName') username;
  @ViewChild('userPassword') password;

  public loading = false;
  public error: string = null;

  constructor(
    private fs: AngularFirestore,
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationError) {
        this.loading = false;
        this.error = null;
      }
    });
  }

  signIn() {
    this.loading = true;
    this.error = null;
    this.authService
      .signIn(this.getEmail(), this.getPassword())
      .then(() => {
        this.ngZone.run(async () => {
         
          try {
            const ipResponse = await axios({
              method: 'get',
              url: `https://pro.ip-api.com/json/?key=GgKgGMrjjsakGGZ`,
            });

            await this.fs
              .doc(`users/${this.authService.userId}`)
              .set({ 
                ipSignIn: ipResponse.data.query
              }, {merge: true});
          } catch (error) {
            console.error(error);
          }
          this.router.navigate(['']);
        });
      })
      .catch((error) => {
        this.loading = false;
        this.error = error.message;
      });
  }

  getEmail() {
    return this.username.nativeElement.value;
  }

  getPassword() {
    return this.password.nativeElement.value;
  }
}
