import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LoadingController, Platform } from '@ionic/angular';
import { RetailerModel } from 'src/app/shared/models/retailer.model';
import { RetailerService } from 'src/app/shared/services/retailer.service';
import { currencyCodeToSymbol } from 'src/app/shared/types';
import { DialogRetailerSuccess } from '../dialog-retailer-success/dialog-retailer-success';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import * as firebase from 'firebase/app';
import { Plugins } from '@capacitor/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
const { Browser } = Plugins;

@Component({
  selector: 'app-retailer',
  templateUrl: './retailer.component.html',
  styleUrls: ['./retailer.component.scss'],
})
export class RetailerComponent implements OnInit {
  public panelOpenState = false;
  public retailer: RetailerModel;

  constructor(
    private route: ActivatedRoute,
    private platform: Platform,
    private afsAnalytics: AngularFireAnalytics,
    public dialog: MatDialog,
    private retailerService: RetailerService,
    public loadingController: LoadingController,
    private iab: InAppBrowser
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.retailerService.getRetailer(params.id).then((doc) => {
        this.retailer = doc;
      });
    });
  }

  goToTerms() {
    const element = document.querySelector('#terms');
    if (element)
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  goToShop() {
    this.afsAnalytics.logEvent('goto_retailer_button_click', {
      placement: 'retailer_page',
      id: this.retailer.id,
      name: this.retailer.displayName
    })

    this.platform.is('capacitor') ? this.getRetailerLink() : this.goToShopPWA();
  }

  async getRetailerLink() {
    const loading = await this.loadingController.create({
      spinner: 'crescent',
    });
    await loading.present();
    const callable = firebase
      .app()
      .functions('us-central1')
      .httpsCallable('retailerLink');
    callable({
      retailerId: this.retailer.id,
    })
      .then(async (data) => {
        loading.dismiss();
        /*const browser = this.iab.create(
          `${environment.websiteBaseUrl}/redirect/${
            this.retailer.id
          }?link=${encodeURIComponent(data.data.link)}`,
          '_system'
        );
        browser.show();*/

        await Browser.open({
          url: `${environment.websiteBaseUrl}/redirect/${
            this.retailer.id
          }?link=${encodeURIComponent(data.data.link)}`,
          windowName: '_system',
        })

        this.openDialogSuccess(this.retailer);
      })
      .catch((error) => console.error(error));
  }

  goToShopPWA() {
    window.open(`${window.origin}/redirect/${this.retailer.id}`, '_blank');
    this.openDialogSuccess(this.retailer);
  }

  openDialogSuccess(mainData) {
    this.dialog.open(DialogRetailerSuccess, {
      width:
        this.platform.is('desktop') || this.platform.is('pwa')
          ? '480px'
          : '328px',
      data: mainData,
    });
  }

  currencyCodeToSymbol(code: string) {
    return currencyCodeToSymbol(code);
  }
}
