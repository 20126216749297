import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IonInput, Platform, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BitcoinRate } from 'src/app/shared/services/bitcoin-rate.service';
import { ScreensizeService } from 'src/app/shared/services/screensize.service';
import { ShoppingSearchService } from 'src/app/shared/services/shopping-search.service';
import { WalletService } from 'src/app/shared/services/wallet.service';
import { DialogRetailerSuccess } from '../retailers/dialog-retailer-success/dialog-retailer-success';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('search') searchElement: IonInput;

  public filter: boolean = false;
  public filter_close: boolean = true;
  countries: any[] = [];
  categories: any[] = [];
  shops: any[];
  visitedShops: any;
  loading: HTMLIonLoadingElement;
  searchText: string = '';
  public selectedCountry: string = '';
  public selectedCategory: string = '';
  public selectedSort: string = 'retailers_popularity';
  pendingSats: number = 0;
  unavailableSats: number = 0;
  availableSats: number = 0;
  bitcoinPrice: number = 0;
  segmentValue: string;
  showSpinner: boolean = false;
  public isReady = true;
  public isDesktop = false;
  private searchSubscription: Subscription;
  private searchingSubscription: Subscription;
  private searchReadySubscription: Subscription;

  public slideOptions = {
    slidesPerView: 1,
    spaceBetween: -60,
  };

  constructor(
    private searchService: ShoppingSearchService,
    private walletService: WalletService,
    private screensizeService: ScreensizeService,
    public authService: AuthService,
    private afsAnalytics: AngularFireAnalytics,
    private apiService: ApiService,
    private bitcoinRate: BitcoinRate,
    public toastController: ToastController,
    private router: Router,
    private platform: Platform,
    public dialog: MatDialog,
    private cRef: ChangeDetectorRef
  ) {
    this.screensizeService.isDesktopView().subscribe((isDesktop) => {
      this.isDesktop = ScreensizeService.switchToDesktop(
        this.isDesktop,
        isDesktop
      );
    });
  }

  async ngOnInit() {
    this.isReady = this.searchService.isReady.value;
    this.searchReadySubscription = this.searchService.isReadyObservable.subscribe(
      (isReady) => {
        this.isReady = isReady;
        if (isReady) {
          this.selectedSort = this.searchService.getSort();
          this.searchText = this.searchService.getSearchText();
          this.selectedCategory = this.searchService.getCategory();
          this.selectedCountry = this.searchService.getCountry();
          this.countries = this.searchService.getCountries();
          this.categories = this.searchService.getCategories();
          this.cRef.detectChanges();
        }
      }
    )

    this.showSpinner = false
    this.searchingSubscription = this.searchService.isSearchingObservable.subscribe(searching => {
      this.showSpinner = searching
      this.cRef.detectChanges()
    })
    
    this.shops = this.searchService.shops.value
    this.searchSubscription = this.searchService.shopsObservable.subscribe(shops => {
      this.shops = shops?.slice(0,8)
      this.cRef.detectChanges()
    })


    this.walletService.pendingSatsObservable.subscribe((pending) => {
      this.pendingSats = pending;
    });

    this.walletService.unavailableSatsObservable.subscribe(unavailable => {
      this.unavailableSats = unavailable
      console.log(unavailable)
    })

    this.walletService.availableSatsObservable.subscribe((available) => {
      this.availableSats = available;
    });
    this.bitcoinRate.dollarToSatoshiObservable.subscribe((rate) => {
      if (rate > 0) {
        this.bitcoinPrice = rate;
      }
    });
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
    this.searchingSubscription?.unsubscribe();
    this.searchReadySubscription?.unsubscribe();
  }

  setSearchText(e) {
    this.searchText = e.target.value
    this.searchService.setSearchText(e.target.value)
    this.searchService.search()
    .catch(error => console.error(error))
  }

  changeSorting(e) {
    this.searchService.setSort(e.detail.value);
    this.searchService.search().catch((error) => console.error(error));
    this.closeFilter();
  }

  changeCategory(e) {
    this.searchService.setCategory(e.detail.value);
    this.searchService.search().catch((error) => console.error(error));
    this.closeFilter();
  }

  changeCountry(e) {
    this.searchService.setCountry(e.detail.value);
    this.searchService.search().catch((error) => console.error(error));
    this.closeFilter();
  }

  openFilter() {
    this.filter = true;
    this.filter_close = false;
  }

  closeFilter() {
    this.filter = false;
    this.filter_close = true;
  }

  handleEnterClick(text) {
    this.afsAnalytics.logEvent('search_retailer', {
      search: this.searchText,
      placement: 'home'
    })
    this.searchService.search().catch((error) => console.error(error));
  }

  async onSegmentChange(e) {
    e.detail.value == 'retailers_popularity'
      ? true
      : !e.detail.value
      ? true
      : await this.getVisitedShops();
  }

  async getVisitedShops() {
    this.showSpinner = true;
    const visitedShops = await this.apiService.getVisitedShops();
    this.visitedShops = visitedShops;
    this.showSpinner = false;
    return false;
  }

  goToRetailer(data) {
    let retailerData = JSON.stringify(data);
    window.localStorage.setItem('retailerData', retailerData);
    this.router.navigateByUrl('/retailer');
  }

  goToShop(data) {
    let retailerData = JSON.stringify(data);
    window.localStorage.setItem('retailerData', retailerData);
    window.open(`${window.origin}/redirect`, '_blank');
    this.openDialogSuccess(data);
  }

  clearInput() {
    if (this.searchService.getSearchText() != '') {
      this.searchText = '';
      this.searchService.setSearchText('');
      this.searchService.search().catch((error) => console.error(error));
    }
  }

  openDialogSuccess(mainData) {
    this.dialog.open(DialogRetailerSuccess, {
      width:
        this.platform.is('desktop') || this.platform.is('pwa')
          ? '480px'
          : '328px',
      data: mainData,
    });
  }

  goToWallet() {
    this.router.navigateByUrl('/wallet');
  }

  browseAll() {
    this.router.navigateByUrl('/shopping');
  }
}
