import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-survey-screenout',
  templateUrl: './survey-screenout.component.html',
  styleUrls: ['./survey-screenout.component.scss']
})
export class SurveyScreenoutComponent implements OnInit {

  private reason: string

  constructor(
    private route: ActivatedRoute,
    private afsAnalytics: AngularFireAnalytics) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.reason = params['reason']
    })

    this.afsAnalytics.logEvent('finished_survey', {
      result: 'survey_screenout_page'
    })
  }

  title() : string {
    if (this.reason === 'vpn') {
      return `VPN was detected.`
    }
    else if (this.reason === 'speeding') {
      return `Going too fast`
    }
    /*else if (this.reason === 'noreward') {
      return `No reward available`
    }*/

    return 'Sorry, that survey was not a match.'
  }

  message() : string {

    if (this.reason === 'vpn') {
      return `You were detected to be using a VPN by the survey provider.
      They don't allow surveys to be completed via VPN.`
    }
    else if (this.reason === 'speeding') {
      return `You were completing the survey too quickly. Survey providers
      check that you are taking your time to answer the questions properly.`
    }
 /*   else if (this.reason === 'noreward') {
      return `There was no reward given for this survey.`
    }
*/
    return  `Before you take a survey, the survey owner asks some preliminary 
    questions to make sure you fit their target profile. Sometimes you're not 
    the right match, as is now the case. Please head back to 
    <a href="${environment.websiteBaseUrl}">satsy.com</a> and try again!`
  }

  close(): void {
    window.close()
  }
}
