import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@ionic/angular';
import { DialogRetailer } from '../retailers/dialog-retailer/dialog-retailer';

@Component({
  selector: 'app-home',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  retailerId: string;
  retailerLink: any;
  constructor(public dialog: MatDialog, private platform: Platform) {}

  async ngOnInit() {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      this.retailerLink = decodeURIComponent(params.get('link'));
      let currentURL = window.location.href.split('?')[0];
      let urlParts = currentURL.split('/');
      this.retailerId = urlParts[urlParts.length - 1];
    } else {
      let urlParts = window.location.href.split('/');
      this.retailerId = urlParts[urlParts.length - 1];
      this.retailerLink = null;
    }
    this.dialog.open(DialogRetailer, {
      width:
        this.platform.is('desktop') || this.platform.is('pwa')
          ? '480px'
          : '328px',
      disableClose: true,
      data: {
        retailerId: this.retailerId,
        retailerLink: this.retailerLink,
      },
    });
  }
}
