import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from '../../../shared/services/survey.service';

@Component({
  selector: 'app-start-survey',
  templateUrl: './start-survey.component.html',
  styleUrls: ['./start-survey.component.scss']
})
export class StartSurveyComponent implements OnInit {

  public sats: string
  public usd: number
  public network: string 
  private link: string
  private surveyId: string
  private clickId: string

  constructor(
    private surveyService: SurveyService,
    private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.clickId = params['clickId']
      this.sats = params['sats']
      this.usd = params['usd']
      this.network = params['network']
      this.surveyId = params['surveyId']
      this.link = params['link']

    })
  }

  start() : void {

    const user = JSON.parse(localStorage.getItem('user'));

    this.surveyService.surveyStarted(this.surveyId, this.clickId, user.stsTokenManager.accessToken)
    .then(response => {
      const rewardId = response.data.data.rewardId
      const link = `${this.link}&tags=rewardId%3D${rewardId}`
      window.open(this.link, "_self")
    })
  }
}
