import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { currencyCodeToSymbol } from 'src/app/shared/types';
@Component({
  selector: 'dialog-retailer-success',
  templateUrl: 'dialog-retailer-success.html',
  styleUrls: ['./dialog-retailer-success.scss'],
})
export class DialogRetailerSuccess {
  mainData: any;
  constructor(
    public dialogRef: MatDialogRef<DialogRetailerSuccess>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    this.mainData = data;
  }
  goToShopping() {
    this.dialogRef.close();
    this.router.navigate(['/shopping']);
  }
  currencyCodeToSymbol(code: string) {
    return currencyCodeToSymbol(code)
  }
}
