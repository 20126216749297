import { Component, HostListener } from '@angular/core';
import { ScreensizeService } from './shared/services/screensize.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import '@capacitor-community/firebase-analytics';
import { Plugins } from '@capacitor/core';
const { FirebaseAnalytics } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private screensizeService: ScreensizeService,
    private afsAnalytics: AngularFireAnalytics,
    private statusBar: StatusBar,
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        this.statusBar.styleDefault();
        this.statusBar.backgroundColorByHexString('#edc128');
        FirebaseAnalytics.setCollectionEnabled({
          enabled: true,
        });
      } else {
        this.afsAnalytics.setAnalyticsCollectionEnabled(true);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }
}
