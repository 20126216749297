import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertController, NavController } from '@ionic/angular';
@Component({
  selector: 'app-survey-redirect-mob',
  templateUrl: './survey-redirect-mob.html',
  styleUrls: ['./survey-redirect-mob.scss'],
})
export class SurveyRedirectMobileComponent implements OnInit {
  link: any;
  sub: any;
  surveyTitle: string;
  showRedirect: boolean = true;
  constructor(
    private activateRouter: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public alertController: AlertController,
    public navCTRL: NavController
  ) {}
  ngOnInit() {
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.activateRouter.snapshot.queryParamMap.get('surveyLink')
    );
    this.surveyTitle = this.activateRouter.snapshot.queryParamMap.get(
      'surveyTitle'
    );
    setTimeout(() => {
      this.showRedirect = false;
    }, 3000);
  }
  async goBack() {
    const alert = await this.alertController.create({
      header: this.surveyTitle,
      message: 'Are you sure you want to leave the survey?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Yes',
          handler: () => {
            this.navCTRL.navigateBack('/surveys');
          },
        },
      ],
    });

    await alert.present();
  }
}
