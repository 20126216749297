import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@ionic/angular';
import { DialogSurveyComponent } from './dialog/dialog-survey.component';

declare const window: any;

@Component({
  selector: 'app-survey-redirect',
  templateUrl: './survey-redirect.component.html',
  styleUrls: ['./survey-redirect.component.scss'],
})
export class SurveyRedirectComponent implements OnInit {

  constructor(

    public dialog: MatDialog, 
    private platform: Platform) {}

  ngOnInit() {
    // const survey = JSON.parse(window.localStorage.getItem('surveyData'));
    // window.Intercom('trackEvent', 'survey-clicked', {
    //   surveyId: survey.id
    // })

    this.dialog.open(DialogSurveyComponent, {
      width:
        this.platform.is('desktop') || this.platform.is('pwa')
          ? '480px'
          : '328px',
      disableClose: true,
    })
  }
} 
