import { Platform } from '@ionic/angular';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogRetailerSuccess } from '../dialog-retailer-success/dialog-retailer-success';
import { currencyCodeToSymbol, RetailerCard } from 'src/app/shared/types';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';

@Component({
  selector: 'app-retailer-card',
  templateUrl: './retailer-card.component.html',
  styleUrls: ['./retailer-card.component.scss'],
  animations: [
    trigger('hover', [
      state('up', style({ transform: 'translateY(-5px)' })),
      state('down', style({ transform: 'translateY(0%)' })),
      transition('up => down', animate('50ms ease-in')),
      transition('down => up', animate('50ms ease-out')),
    ]),
  ],
})
export class RetailerCardComponent {
  @Input() retailer: RetailerCard;
  @Input() location: string;

  public hoverState = 'down';

  constructor(
    private platform: Platform,
    public dialog: MatDialog,
    private router: Router,
    private afsAnalytics: AngularFireAnalytics,
    private config: AngularFireRemoteConfig,
  ) {}

  goToRetailer() {
    this.afsAnalytics.logEvent('open_retailer_click', {
      placement: location,
      id: this.retailer.id,
      name: this.retailer.displayName
    })

    this.router.navigate([`/retailer/${this.retailer.id}`]);
  }

  goToShop() {
    this.afsAnalytics.logEvent('goto_retailer_button_click', {
      placement: location,
      id: this.retailer.id,
      name: this.retailer.displayName
    })

    window.open(`${window.origin}/redirect/${this.retailer.id}`, '_blank');
    this.openDialogSuccess();
  }

  openDialogSuccess() {
    this.dialog.open(DialogRetailerSuccess, {
      width:
        this.platform.is('desktop') || this.platform.is('pwa')
          ? '480px'
          : '328px',
      data: this.retailer,
    });
  }

  currencyCodeToSymbol(code: string) {
    /*await this.config.fetchAndActivate()
    const currencies = await this.config.getValue('currencies')
    console.log(currencies)
*/

    console.log('heellllo')

    return currencyCodeToSymbol(code);
  }

  mouseEnter() {
    this.hoverState = 'up';
  }

  mouseLeave() {
    this.hoverState = 'down';
  }
}
