import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-history-table',
  templateUrl: './history-table.component.html',
  styleUrls: ['./history-table.component.scss']
})
export class HistoryTableComponent implements OnInit  {

  @Input() balance: number

  private field = 'modifiedAt'
  private pageSize = 5
  public pageNumber = 1
  public rewardIds: Array<string>
  public rewardCount: number
  private userDocSubscription: Subscription

  public isDesktop = false
  private lastDoc = null

  public isReady = false

  disableNext: boolean = false;
  disablePrev: boolean = false;

  constructor(
    public authService: AuthService,
    private fs: AngularFirestore
  ) { }

  ngOnDestroy(): void {
    this.userDocSubscription.unsubscribe()
  }

  ngOnInit(): void {

    // Get the total rewards
    this.userDocSubscription?.unsubscribe()
    this.userDocSubscription = this.fs.doc(`users/${this.authService.userId}`)
    .snapshotChanges().subscribe(snapshot => {
      const userDoc = snapshot.payload.data() as any
      this.rewardCount = userDoc.rewardCount

      // Get the first page of data
      this.isReady = false
      this.collapse()
    })
  }

  collapse() {
    
    this.pageNumber = 1
    this.fs.collection(`rewards`, ref => ref
      .where('userId', '==', this.authService.userId)
      .orderBy(this.field, 'desc')
      .limit(this.pageSize))
      .get().subscribe(snapshot => {
        this.lastDoc = snapshot.docs[snapshot.size - 1]
        this.rewardIds = new Array<string>()
        snapshot.forEach(doc => {
          this.rewardIds.push(doc.id)
        })
        this.isReady = true
    })
  }

  nextPage(): void {
    this.pageNumber++

    this.fs.collection(`rewards`, ref => ref
    .where('userId', '==', this.authService.userId)
    .orderBy(this.field, 'desc')
    .startAfter(this.lastDoc)
    .limit(this.pageSize))
    .get().subscribe(snapshot => {
      this.lastDoc = snapshot.docs[snapshot.size - 1]
      snapshot.forEach(doc => {
        const findRewardIdMatch = (element) => { 
          return element === doc.id
        }
        const rewardIndex = this.rewardIds.findIndex(findRewardIdMatch)
        if (rewardIndex > -1) {
          this.rewardIds.splice(rewardIndex, 1);
        }
        this.rewardIds.push(doc.id)
      })
    })
  }

  isFirstPage(): boolean {
    return this.pageNumber === 1
  }

  isLastPage(): boolean {
    return this.pageNumber === this.totalPages()
  }

  totalPages() : number {
    return Math.ceil(this.rewardCount / this.pageSize)
  }
}
