import { Component } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { FxService } from 'src/app/shared/services/fx.service';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations'
import { CurrencyCode } from 'src/app/shared/types';

@Component({
  selector: 'app-qualification-card',
  templateUrl: './qualification-card.component.html',
  styleUrls: ['./qualification-card.component.scss'],
  animations: [
    trigger('hover', [
      state('up', style({transform: 'translateY(-5px)'})),
      state('down', style({transform: 'translateY(0%)'})),
      transition('up => down', animate('50ms ease-in')),
      transition('down => up', animate('50ms ease-out'))
    ]),
  ]
})
export class QualificationCardComponent {

  private starterReward: number
  private rates = null
  public hoverState = 'down'

  constructor(
    private fxService: FxService,
    private config: AngularFireRemoteConfig) {
    this.config.fetchAndActivate()
    .then(async () => {
      this.starterReward = await this.config.getNumber('starter_survey_reward')
      this.fxService.isReadyObservable.subscribe(ready => {
        if (ready) {
          this.fxService.bitcoinRatesObservable.subscribe(rates => {
            this.rates = rates
          })
        }
      })
    })
  }

  get satsPayable(): string {
    if (this.rates) {
      return `${Math.round(this.usdPayable / this.rates[CurrencyCode.USD] * 100000000)}`
    }
    return '0'
  }

  get usdPayable(): number {
    return this.starterReward
  }

  mouseEnter() {
    this.hoverState = 'up'
  }

  mouseLeave() {
    this.hoverState = 'down'
  }
}
