import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WithdrawComponent } from '../withdraw/withdraw.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BitcoinRate } from 'src/app/shared/services/bitcoin-rate.service';
import { formatNumber } from 'src/app/shared/utils';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { ScreensizeService } from 'src/app/shared/services/screensize.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit, OnDestroy {
  public balance = 0;
  public sats = '0';
  public showWithdrawButton = false;
  public isProcessing = false;
  public showHistoryTable = false;
  public initialThresholdMet = false;
  public isReady = false;
  public rate = 0;
  public isDesktop = false;

  constructor(
    private config: AngularFireRemoteConfig,
    private bitcoinRate: BitcoinRate,
    public authService: AuthService,
    public fs: AngularFirestore,
    public dialog: MatDialog,
    private afsAnalytics: AngularFireAnalytics,
    private screensizeService: ScreensizeService
  ) {
    this.screensizeService.isDesktopView().subscribe((isDesktop) => {
      this.isDesktop = ScreensizeService.switchToDesktop(
        this.isDesktop,
        isDesktop
      );
    });
  }

  ngOnInit(): void {
    this.updateBalance();

    this.bitcoinRate.dollarToSatoshiObservable.subscribe((rate) => {
      this.rate = rate;
      if (rate > 0) {
        this.sats = formatNumber(Math.round((this.balance / rate) * 100000000));
      }
    });
  }

  ngOnDestroy(): void {}

  updateBalance(): void {
    const ref = this.fs.collection(`rewards`).ref
    ref.where('userId', '==', this.authService.userId)
      .where("status", "in", ["available", "withdraw_failed", "processing"])
      .get()
      .then(snapshot =>{

        this.balance = 0
        snapshot.docs.forEach(doc => {
          this.balance += doc.data()?.userRevenue
        })
        
        this.showWithdrawButton = this.balance > 0
        this.sats = formatNumber( Math.round(this.balance / this.bitcoinRate.dollarToSatoshi.getValue() * 100000000) )

        this.fs.doc(`users/${this.authService.userId}`).ref.get().then(async doc => {
          this.showHistoryTable = doc.data().rewardCount > 0
          this.config.fetchAndActivate()
          .then(async () => {
            const initialWithdrawalThreshold = await this.config.getNumber('user_withdrawal_threshold_initial')
            this.initialThresholdMet = doc.data().initialThreshold === true || initialWithdrawalThreshold < this.balance
            this.isReady = true
          })
        })
      })
  }

  withdraw(): void {

    this.afsAnalytics.logEvent('withdraw_button_clicked', {
      placement: 'wallet_page'
    })

    this.showWithdrawButton = false;
    this.isProcessing = true;
    const dialogRef = this.dialog.open(WithdrawComponent, {
      width: '350px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.isProcessing = false;
      this.updateBalance();
    });
  }
}
