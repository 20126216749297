import { RedirectComponent } from './components/redirect/redirect.component';
import { NgModule } from '@angular/core';

import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// Import all the components for which navigation service has to be activated
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { SurveysComponent } from './components/surveys/surveys/surveys.component';
import { GamesComponent } from './components/games/games/games.component';
import { ShoppingComponent } from './components/shopping/shopping/shopping.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { WalletComponent } from './components/account/wallet/wallet.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInCardComponent } from './components/sign-in/sign-in-card/sign-in-card.component';
import { ForgotPasswordCardComponent } from './components/sign-in/forgot-password-card/forgot-password-card.component';
import { JoinCardComponent } from './components/sign-in/join-card/join-card.component';
import { VerifyCardComponent } from './components/sign-in/verify-card/verify-card.component';
import { StartSurveyComponent } from './components/surveys/start-survey/start-survey.component';
import { SurveySuccessComponent } from './components/surveys/survey-success/survey-success.component';
import { SurveyErrorComponent } from './components/surveys/survey-error/survey-error.component';
import { SurveyScreenoutComponent } from './components/surveys/survey-screenout/survey-screenout.component';
import { HomeComponent } from './components/home/home.component';
import { RetailerComponent } from './components/retailers/retailer/retailer.component';
import { FaqComponent } from './components/faq/faq.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MissingSatsbackComponent } from './components/missing-satsback/missing-satsback.component';
import { SupportComponent } from './components/support/support.component';
import { SurveyRedirectComponent } from './components/surveys/survey-redirect/survey-redirect.component';
import { StarterSurveyComponent } from './components/surveys/starter-survey/starter-survey.component';
import { QualificationSurveyComponent } from './components/surveys/qualification-survey/qualification-survey.component';
import { SurveyRedirectMobileComponent } from './components/surveys/survey-redirect-mob/survey-redirect-mob';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'surveys',
        component: SurveysComponent,
        // children: [
        //   { path: '', redirectTo: 'list', pathMatch: 'full' },
        //   {
        //     path: 'qualification',
        //     canActivate: [SurveyStateGuard],
        //     component: QualificationComponent,
        //   },
        //   {
        //     path: 'list',
        //     canActivate: [SurveyStateGuard],
        //     component: SurveyListComponent,
        //   },
        //   { path: 'survey-problem', component: SurveyProblemComponent },
        // ],
      },
      { path: 'retailer/:id', component: RetailerComponent },
      { path: 'home', component: HomeComponent },
      { path: 'shopping', component: ShoppingComponent },
      { path: 'games', component: GamesComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'wallet', component: WalletComponent },
      { path: 'support', component: SupportComponent },
      { path: 'missing-satsback', component: MissingSatsbackComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'faq', component: FaqComponent },
    ],
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    children: [
      { path: '', component: SignInCardComponent },
      { path: 'forgot', component: ForgotPasswordCardComponent },
      { path: 'join', component: JoinCardComponent },
      { path: 'verify', component: VerifyCardComponent },
    ],
  },
  {
    path: 'start-survey',
    component: StartSurveyComponent,
  },
  {
    path: 'survey-success',
    component: SurveySuccessComponent,
  },
  {
    path: 'survey-error',
    component: SurveyErrorComponent,
  },
  {
    path: 'survey-screenout',
    component: SurveyScreenoutComponent,
  },
  { path: 'redirect/:id', component: RedirectComponent },
  {
    path: 'survey-redirect-mob',
    component: SurveyRedirectMobileComponent,
  },
  { path: 'survey-redirect', component: SurveyRedirectComponent },
  { path: 'starter-survey', component: StarterSurveyComponent },
  { path: 'qualification-survey', component: QualificationSurveyComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
