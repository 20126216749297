import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QualificationComponentConfig, QualificationInputType, QualificationQuestionType } from './qualification.config';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { upperCase } from "upper-case";
import { QualificationService } from './qualification.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatePipe } from '@angular/common';
import { SurveyService, SurveyState } from '../../../shared/services/survey.service';
import { AngularFirestore } from '@angular/fire/firestore';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.scss']
})
export class QualificationComponent implements OnInit {

  
  @Output() finished: EventEmitter<void> = new EventEmitter<void>();
  @Output() answered: EventEmitter<void> = new EventEmitter<void>();

  public config: QualificationComponentConfig
  public hasApiError = false
  public errorMessage = ''
  public disable = false
  public skipDisabled = false
  public date: string
  public minDate: Date
  public maxDate: Date
  public surveysExist = false

  public formGroup = new FormGroup({
    text: new FormControl('', Validators.required),
    number: new FormControl('', 
    Validators.compose([
      Validators.required,
       Validators.minLength(1)
      ])
    ), 
    single: new FormControl('', Validators.required),
    multi: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
  });

  constructor(
    private authService: AuthService,
    private fs: AngularFirestore,
    private surveyService: SurveyService,
    private datePipe: DatePipe,
    private qualificationService: QualificationService) {}
    
  ngOnInit(): void {

    this.surveyService.surveysExist.subscribe((surveysExist) => {
      this.surveysExist = surveysExist
    })

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear -  17, 11, 31);

    this.config = this.qualificationService.currentQuestion()
  }

  maximumMessage(): string {
    if (this.config?.inputType !== QualificationInputType.Multi) {
      return null
    }
    if (this.config?.possibleAnswers.length < 9) {
      return null
    }
    return `Pick a maximum of 9 answers`
  }

  isNextDisabled() : boolean {
    if (this.disable)
      return true

    if (this.config?.inputType === QualificationInputType.Text) {
      return this.config?.inputType && this.formGroup.get('text').hasError('required')
    }
    else if (this.config?.inputType === QualificationInputType.Number) {
      return this.config?.inputType && this.formGroup.get('number').hasError('required') ||
        this.config?.inputType && this.formGroup.get('number').hasError('minlength')
    }
    else if (this.config?.inputType === QualificationInputType.Single) {
      return this.config?.inputType && this.formGroup.get('single').hasError('required')
    }
    else if (this.config?.inputType === QualificationInputType.Multi) {
      return this.config?.inputType && this.formGroup.get('multi').hasError('required')
    }
    else if (this.config?.inputType === QualificationInputType.Date) {
      return this.config?.inputType && this.formGroup.get('date').hasError('required')
    }
    return false
  }

  isSkipEnabled(): boolean {
    return this.config?.skippable
  }

  onInputFocus() {
    this.hasApiError = false
  }

  onNext(isSkipped: boolean) {
    this.disable = true
    this.skipDisabled = true
    this.answered.emit()

    let answers: string[]
    if (!isSkipped) {

      if (this.config?.inputType === QualificationInputType.Text) {
        answers = [upperCase(this.formGroup.get('text').value)]
      }
      else if (this.config?.inputType === QualificationInputType.Number) {
        answers = [this.formGroup.get('number').value]
      }
      else if (this.config?.inputType === QualificationInputType.Single) {
        answers = [this.formGroup.get('single').value.code]
      }
      else if (this.config?.inputType === QualificationInputType.Multi) {
        answers = new Array<string>()
        this.formGroup.get('multi').value.forEach(element => {
          answers.push(element.code)
        })
      }
      else if (this.config?.inputType === QualificationInputType.Date) {
        const date = new Date(this.date)
        answers = [this.datePipe.transform(date, 'yyyy-MM-dd')]
      }
    }

    this.surveyService.answerQuestion(
      this.authService.userId,
      this.surveyService.qualificationQuestion(),
      answers,
      isSkipped)
    .then( (result: any) => {
      if (result.state === SurveyState.Error) {
        this.hasApiError = true

        if (result.question.question_id === QualificationQuestionType.Zip) {
          this.errorMessage = 'Postal/zip incorrectly formatted'
        }
        else {
          this.errorMessage = 'Answer was not valid'
        }
      } else {
        this.hasApiError = false
        this.errorMessage = ''
        
        const config = this.qualificationService.currentQuestion()

        if (this.qualificationService.isFinished()) {
          this.finished.emit()
        }
        this.config = config
        this.disable = false
        this.skipDisabled = false

        this.formGroup.setValue({
          single: null,
          multi: null,
          date: null,
          number: null,
          text: null
        })
      }
    })
    .catch( (error) => {
      console.error(error)
      this.disable = false
      this.hasApiError = true
    })
  }
}


