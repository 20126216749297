import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ApiService } from 'src/app/shared/services/api.service';
import { currencySymbolToCode } from 'src/app/shared/types';

@Component({
  selector: 'app-missing-satsback',
  templateUrl: './missing-satsback.component.html',
  styleUrls: ['./missing-satsback.component.scss'],
})
export class MissingSatsbackComponent implements OnInit {
  one: boolean = true;
  two: boolean = false;
  three: boolean = false;
  issueType: any;
  currencies: any;
  currencyType: any;
  myRewards: any;
  selectedReward: string;
  timingArray: any;
  selectedTime: any;
  retailerOrderNumber: string;
  retailerPrice: string;
  showSpinner: boolean = false;
  private clickId = '';

  constructor(
    private apiService: ApiService,
    private functions: AngularFireFunctions,
    private afsAnalytics: AngularFireAnalytics
  ) {}

  async ngOnInit() {
    this.issueType = 'noSatsBack';
    this.currencies = await this.apiService.getCurrencies();
    this.currencyType = this.currencies[0].symbol;
    this.goToFirstSlide();
    this.getRewards();
  }

  goToFirstSlide() {
    this.afsAnalytics.logEvent('missing_satsback', {
      stage: 1
    })

    var numberOne = document.getElementById('number-one');
    numberOne.style.cssText =
      'color: #fff; background: #1C1B56; width: 36px; height: 36px; border-radius: 20px; font-weight: bold; font-size: 16px;';

    var numberTwo = document.getElementById('number-two');
    numberTwo.style.cssText =
      'color: #1c1b56; background: #cccccc; width: 36px; height: 36px; border-radius: 20px; font-weight: bold; font-size: 16px;';

    var numberThree = document.getElementById('number-three');
    numberThree.style.cssText =
      'color: #1c1b56; background: #cccccc; width: 36px; height: 36px; border-radius: 20px; font-weight: bold; font-size: 16px;';
  }
  goToSecondSlide() {
    this.afsAnalytics.logEvent('missing_satsback', {
      stage: 2
    })

    this.one = false;
    this.two = true;
    this.three = false;
    var numberOne = document.getElementById('number-one');
    numberOne.style.cssText =
      'color: #1c1b56; background: #cccccc; width: 36px; height: 36px; border-radius: 20px; font-weight: bold; font-size: 16px;';

    var numberTwo = document.getElementById('number-two');
    numberTwo.style.cssText =
      'color: #fff; background: #1C1B56; width: 36px; height: 36px; border-radius: 20px; font-weight: bold; font-size: 16px;';

    var numberThree = document.getElementById('number-three');
    numberThree.style.cssText =
      'color: #1c1b56; background: #cccccc; width: 36px; height: 36px; border-radius: 20px; font-weight: bold; font-size: 16px;';
  }

  goToThirdSlide() {
    this.afsAnalytics.logEvent('missing_satsback', {
      stage: 3
    })

    this.one = false;
    this.two = false;
    this.three = true;
    var numberOne = document.getElementById('number-one');
    numberOne.style.cssText =
      'color: #1c1b56; background: #cccccc; width: 36px; height: 36px; border-radius: 20px; font-weight: bold; font-size: 16px;';

    var numberTwo = document.getElementById('number-two');
    numberTwo.style.cssText =
      'color: #1c1b56; background: #cccccc; width: 36px; height: 36px; border-radius: 20px; font-weight: bold; font-size: 16px;';

    var numberThree = document.getElementById('number-three');
    numberThree.style.cssText =
      'color: #fff; background: #1C1B56; width: 36px; height: 36px; border-radius: 20px; font-weight: bold; font-size: 16px;';
  }

  changeIssueType(e) {
    this.issueType = e.detail.value;
  }
  changeCurrency(e) {
    this.currencyType = e.detail.value;
  }
  async getRewards() {
    const rewards = await this.apiService.getMyRewards();
    this.myRewards = await this.groupBy(rewards, 'company');
    // this.selectedReward = Object.keys(this.myRewards)
    //   ? Object.keys(this.myRewards)[0]
    //   : '';
    this.selectedReward = 'selectRetailer';
  }

  groupBy(objectArray, property) {
    let groupedArr = [];
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        groupedArr.push({ key });
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }
  changeReward(e) {
    if (e.detail.value != 'selectRetailer') {
      this.timingArray = this.myRewards[e.detail.value];
      this.selectedTime = this.timingArray[0].createdAt;
      this.clickId = this.timingArray[0].rewardId;
    } else {
      this.timingArray = null;
      this.selectedTime = null;
      this.clickId = null;
    }
  }
  changeTime(e) {
    if (e.detail.value) {
      const click = this.timingArray.find(
        (click) => click.createdAt === e.detail.value
      );
      this.clickId = click.rewardId;
      this.selectedTime = e.detail.value;
    }
  }
  submitSatsbackClaim() {
    this.showSpinner = true;
    const callable = this.functions.httpsCallable('missingSatsbackRequest');
    callable({
      issueType: this.issueType,
      amount: this.retailerPrice,
      currency: currencySymbolToCode(this.currencyType),
      clickId: this.clickId,
      orderNumber: this.retailerOrderNumber,
    }).subscribe((data) => {
      this.showSpinner = false;
      this.goToThirdSlide();
    });
  }
}
