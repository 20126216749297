import { Injectable, NgZone } from '@angular/core';
import { User } from './user';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as fb from 'firebase';
import * as moment from 'moment';

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: fb.User;
  userDoc: fb.firestore.DocumentData;

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        this.userDoc = (
          await this.afs.doc(`users/${user.uid}`).ref.get()
        ).data();
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        localStorage.setItem('userDoc', JSON.stringify(this.userDoc));

        window.Intercom('boot', {
          app_id: "gd4var2j",
          email: user.email, 
          user_id: user.uid 
        });

      } else {
        window.Intercom('shutdown')

        localStorage.setItem('user', null);
        localStorage.setItem('userDoc', null);
      }
    });
  }

  signIn(email: string, password: string): Promise<void> {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        localStorage.setItem('tempData', btoa(password));
        this.userData = result.user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
        this.setUserData(this.userData);
      });
  }

  signUp(email: string, password: string): Promise<void> {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        if (userCredential) {
          if (userCredential) {
            await this.sendVerificationMail();
            await this.setUserData(userCredential.user);
          }
        }
        this.router.navigate(['/']);
      });
  }

  async sendVerificationMail(): Promise<void> {
    await fb.auth().currentUser.sendEmailVerification();
  }

  forgotPassword(passwordResetEmail: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  getUser() {
    return localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null;
  }

  getUserDoc() {
    return localStorage.getItem('userDoc')
      ? JSON.parse(localStorage.getItem('userDoc'))
      : null;
  }

  get userId(): string {
    return this.getUser().uid;
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }

  async reloadUser(): Promise<void> {
    await fb.auth().currentUser.reload();
    const user = await this.afAuth.currentUser;
    await this.setUserData(user);
  }

  get isVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null && user.emailVerified !== false ? true : false;
  }

  async googleLogin(): Promise<void> {
    const provider = new fb.auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    return this.setUserData(credential.user);
  }

  async authLogin(provider: fb.auth.AuthProvider): Promise<void> {
    try {
      const result = await this.afAuth.signInWithPopup(provider);
      this.setUserData(result.user);
      this.router.navigate(['dashboard']);
    } catch (error) {
      window.alert(error);
    }
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserData(user): Promise<void> {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  async signOut(): Promise<void> {
    await this.afAuth.signOut();
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('tempData');
    window.location.reload();
    //this.router.navigate(['sign-in']);
  }

  async getUserProfile() {
    const currentUser = JSON.parse(window.localStorage.getItem('user'));
    const userData = await (
      await this.afs.doc(`users/${currentUser.uid}`).ref.get()
    ).data();
    return userData;
  }
  setProfile(value: any, userData: any) {
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        if (userData.key == 'Name') {
          this.afs.doc(`users/${user.uid}`).update({
            displayName: value,
          });
        } else if (userData.key == 'Gender') {
          this.afs.doc(`users/${user.uid}`).update({
            gender: value,
          });
        } else if (userData.key == 'Date Of Birth') {
          this.afs.doc(`users/${user.uid}`).update({
            dob: moment(value).format('DD MMM, YYYY'),
          });
        } else if (userData.key == 'Country') {
          this.afs.doc(`users/${user.uid}`).update({
            defaultCountry: value,
          });
        } else if (userData.key == 'Password') {
          // user.updatePassword(value);
          let pass = window.localStorage.getItem('tempData');
          await this.reauthenticate(atob(pass));
          user.updatePassword(value).then(() => {

            window.Intercom('trackEvent', 'reset-password')

            this.signOut();
          });
        } else if (userData.key == 'Email') {
          let pass = window.localStorage.getItem('tempData');
          await this.reauthenticate(atob(pass));
          user.updateEmail(value).then(async () => {
            await this.sendVerificationMail();
            this.afs.doc(`users/${user.uid}`).update({
              email: value,
              emailVerified: false,
            });
            this.signOut();
          });
        }
      }
    });
  }

  reauthenticate = async (currentPassword) => {
    const user = await this.afAuth.currentUser;
    var cred = fb.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(cred);
  };

  removeProfileItem(value: any, userData: any) {
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        if (userData.key == 'Name') {
          this.afs.doc(`users/${user.uid}`).update({
            displayName: '',
          });
        } else if (userData.key == 'Gender') {
          this.afs.doc(`users/${user.uid}`).update({
            gender: '',
          });
        } else if (userData.key == 'Date Of Birth') {
          this.afs.doc(`users/${user.uid}`).update({
            dob: '',
          });
        }
      }
    });
  }
}
