import { Component, Input } from '@angular/core'

export interface SurveyMessage {
  header: string,
  message: string,
  button: string,
  action: () => void
}

@Component({
  selector: 'app-survey-message',
  templateUrl: './survey-message.component.html',
  styleUrls: ['./survey-message.component.scss'],
})
export class SurveyMessageComponent {

  @Input() public data: SurveyMessage = null

  constructor() { }

  click(): void {
    this.data?.action()
  }
}
